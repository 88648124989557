.avatar--image {
  height: 32px;
  width: 32px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.avatar--text {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background-color: $c-primary;
  color: $c-white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(12px, 3vw, 14px);
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.avatar--xLarge {
  height: 128px;
  width: 128px;
  font-size: clamp(24px, 3vw, 32px);
}
.avatar--large {
  height: 64px;
  width: 64px;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 800;
}

.avatar--medium {
  height: 40px;
  width: 40px;
  font-size: clamp(12px, 3vw, 14px);
  font-weight: 600;
}

.avatar--xMedium {
  height: 48px;
  width: 48px;
  font-size: clamp(12px, 3vw, 16px);
  font-weight: 600;
}

.avatar--small {
  height: 32px;
  width: 32px;
  font-size: clamp(12px, 3vw, 14px);
}

.avatar--normal {
  border: none;
}
.avatar--bordered {
  border: 1px solid $c-primary;
}

.avatar--bg--default {
  background-color: rgba($c-primary, 1);
  color: $c-white;
}

.avatar--bg--primary {
  background-color: $c-primary-light !important;
  color: $c-primary;
}

.avatar--bg--second {
  background-color: rgba($c-red, 0.1);
  color: $c-red;

  &::before {
    content: "";
    background-color: rgba($c-red, 0.2);
    margin: auto;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    width: 90%;
    height: 90%;
    border-radius: 100px;
  }
}

.profile--avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-bottom: 24px;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .name {
      font-size: clamp(16px, 3vw, 20px);
      font-weight: 700;
      color: $c-black;
    }
    .source {
      font-size: clamp(14px, 3vw, 14px);
      font-weight: 400;
      color: $c-gray;
    }
  }
}
