.alert--wrapper {
  max-width: fit-content;
  & .alert-color {
    border-radius: 4px;
    border: none;
    gap: 16px;

    &.ant-alert-info {
      background: $c-primary-light;

      & .ant-alert-icon {
        color: $c-blue-light2;
      }

      & .ant-alert-message {
        color: $c-dark-3;
        font-size: 12px;
      }
    }

    &.ant-alert-warning {
      background: $c-yellow-light;

      & .ant-alert-icon {
        color: $c-yellow-light1;
      }

      & .ant-alert-message {
        color: $c-yellow-light2;
        font-size: 12px;
      }
    }

    .ant-alert-with-description {
      align-items: center;
    }

    &.ant-alert-error {
      & .ant-alert-icon {
        color: $c-danger;
      }

      & .ant-alert-message {
        font-weight: 700;
        color: $c-danger;
        margin-bottom: 0;
      }

      & .ant-alert-description {
        color: $c-danger;
      }
    }
  }
}

// Notification
.ant-notification {
  z-index: 9999999;
  color: $c-gray-light;
  .ant-notification-notice {
    border-radius: 8px;


    .ant-notification-notice-close {
      top: 16px;
      right: 16px;
    }

    .ant-notification-notice-message {
      h3.title {
        font-weight: 600;
        font-size: clamp(16px, 3vw, 18px);
      }
    }
    .ant-notification-notice-description {
      .description {
        p {
          margin-bottom: 0;
        }
      }
    }

    a {
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

