.w-full {
  width: 100%;
}
.LMF-formula-modal {
  .ant-modal-content {
    border-radius: 24px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .ant-modal-close {
    display: none;
  }
  &__title {
    color: #8aceff;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    &--adherent {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #1b4dce;
        font-weight: 600;
        font-size: 16px;
        > span:last-child {
          cursor: pointer;
        }
      }
      > ul {
        gap: 0 !important;
        font-size: 14px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .capitalize {
            text-transform: capitalize;
          }
          > span:last-child {
            font-weight: 400;
          }
        }
      }
    }
  }
  &__actions {
    display: flex;
    gap: 8px;
    > div {
      flex: 1;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &--product {
      padding: 18px 24px;
      background: rgba(98, 159, 213, 0.05);
      border-radius: 16px;
      display: flex;
      align-items: center;
      color: $c-lmf-primary;
      font-weight: 800;
      justify-content: space-between;
      &__title {
        font-size: 24px;
        font-weight: 900;
      }
      &__price {
        display: flex;
        flex-direction: column;
        align-items: end;
        line-height: 1;
        .price {
          font-size: 24px;
          font-weight: 900;
        }
      }
      &__detail {
        display: flex;
        flex-direction: column;
        gap: 4px;
        &--title {
          color: #1b4dce;
          font-weight: 400;
          font-size: 14px;
        }
        &--prices {
          list-style: none;
          display: flex;
          flex-direction: column;
          > li {
            display: flex;
            align-items: center;
            > span {
              color: $c-lmf-primary;
              font-size: 16px;
              &:first-child {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    margin-bottom: 16px;
    li {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 600;
      color: $c-lmf-primary;
    }
  }
}
.LMF-content {
  .select__wrapper
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid transparent;
    background-color: $c-lmf-input-bg !important;
    color: $c-lmf-primary;
    height: 50px;
    box-shadow: none !important;
  }
  .select__wrapper:not(.__error) {
    .ant-select.ant-select-disabled .ant-select-selector{
      background: $c-lmf-disabled !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: transparent !important;
    }
    .ant-select:not(.ant-select-customize-input).valid .ant-select-selector {
      border-color: $c-lmf-primary !important;
    }
  }
  .ant-select-selection-item {
    color: $c-lmf-primary;
    font-weight: 500;
    padding-top: 5px !important;
  }
  .ant-select-selection-placeholder {
    padding-top: 5px !important;
    color: rgba(90, 52, 140, 0.7);
  }
  .ant-picker-suffix,
  .ant-select-arrow {
    color: $c-lmf-primary;
  }
  .label__area {
    color: $c-lmf-primary;
    font-weight: 600;
    flex: 1;
    label span.underline {
      text-decoration: underline;
    }
  }
  .label__optional {
    font-weight: 300;
    font-size: 12px;
    color: $c-lmf-primary;
  }
  .input__wrapper input,
  .datepicker__wrapper .ant-picker {
    background: $c-lmf-input-bg;
    color: $c-lmf-primary;
    font-weight: 600;
    height: 50px;
    &[disabled],
    &.ant-picker-disabled {
      background: $c-lmf-disabled !important;
    }
    input {
      color: $c-lmf-primary !important;
    }
  }
  .input__wrapper:not(.__error) .valid input {
    border: 1px solid $c-lmf-primary !important;
  }
  .datepicker__wrapper:not(.__error) .ant-picker.valid {
    border: 1px solid $c-lmf-primary !important;
  }
  .select__wrapper.__error {
    .ant-select-selector {
      border-color: #d7322a !important;
    }
    .ant-select-arrow {
      color: #d7322a !important;
    }
  }
  .input__wrapper.__error input,
  .datepicker__wrapper.__error .ant-picker {
    background: $c-lmf-input-bg !important;
    background-color: $c-lmf-input-bg !important;
    border: 1px solid #d7322a !important;
    .ant-picker-suffix {
      color: #d7322a !important;
    }
  }
  .checkbox__wrapper input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 2px solid $c-lmf-primary;
    background-color: $c-lmf-input-bg !important;
    &:before {
      filter: hue-rotate(55deg);
    }
  }
  .checkbox__wrapper input[type="checkbox"]:checked {
    background-color: $c-lmf-primary !important;
    &:before {
      filter: brightness(37.5);
    }
  }
  .input__wrapper .ant-input-affix-wrapper {
    padding: 0 !important;
    background: transparent !important;
    box-shadow: none;
    input:focus {
      background-color: $c-lmf-input-bg !important;
    }
  }
  .ant-input-suffix {
    position: absolute;
    right: 15px;
    top: 14px;
  }
}

.LMF-help-modal {
  span.highlight {
    color: #ff9d00;
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: $c-lmf-primary;
    text-align: center;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-close {
    display: none;
  }
  .modal-actions {
    display: flex;
    width: 100%;
    gap: 4px;
  }
  .modal-done {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
}

.B2C_LMF {
  min-height: 100vh;
  height: 100%;
  background: #fff;
  .add-upsell {
    background: rgba(238, 235, 244, 0.5);
    padding: 16px 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 24px;
    &.blur {
      opacity: 0.4;
    }
    &__price {
      display: flex;
      align-items: center;
      color: $c-lmf-primary;
      gap: 4px;
      width: 90px;
      justify-content: center;
      &--integer {
        font-size: 50px;
        font-weight: 900;
      }
      &--decimal {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        align-items: center;
      }
    }
  }
  .cart-button {
    margin: -10px auto;
    button {
      padding: 6px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
  .thank-you {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;
    height: 60vh;
    p {
      color: $c-lmf-primary;
      max-width: 540px;
      text-align: center;
      font-size: clamp(18px, 3vw, 14px);
      line-height: 1.3;
    }
  }
  .custom_offer {
    background: rgba(238, 235, 244, 0.5);
    padding: 22px 36px;
    margin: 36px 0 100px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 820px;
    &--info {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: $c-lmf-primary;
      &__title {
        font-weight: 600;
        font-size: 18px;
      }
      &__description {
        font-size: 14px;
      }
    }
  }
  .swiper-slide-next {
    .pricing-lmf__card {
      border-radius: 8px;
    }
  }
  .swiper-slide:has(+ .swiper-slide-next) {
    .pricing-lmf__card {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right-width: 0;
    }
  }

  .swiper-slide-next + .swiper-slide {
    .pricing-lmf__card {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left-width: 0;
    }
  }
  .custom-budget {
    background-color: rgba(238, 235, 244, 0.5);
    margin-top: 16px;
    border-radius: 8px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    .input__wrapper input {
      border: 1px solid #5a348c;
    }
  }
  .button__lmf.back {
    position: absolute;
    left: 0;
  }
  span.highlight {
    color: #ff9d00;
  }
  .header-title {
    color: #8aceff;
    font-weight: 600;
    font-size: 18px;
  }
  .header-description {
    font-size: 35px;
    font-weight: 600;
    color: $c-lmf-primary;
    text-align: center;
  }
  .button__lmf.next button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 316px;
  }
  .b2c-lmf-radio--card {
    max-width: 992px;
    margin: 56px 0;
    > div {
      display: flex;
      gap: 16px;
      > label {
        border: none !important;
        flex: 1;
        opacity: 0.3;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 1;
          color: $c-lmf-primary !important;
        }
        .radio-title {
          font-weight: 600;
          font-size: 16px;
          color: $c-lmf-primary !important;
          margin-bottom: 8px;
        }
        p {
          color: $c-lmf-primary;
          line-height: 18px;
          font-size: 14px;
          font-weight: 300;
        }
        &:has(:checked) {
          opacity: 1;
          color: $c-lmf-primary !important;
          border: none !important;
          box-shadow: none !important;
        }
        &::before {
          background: none !important;
        }
      }
    }
  }
  .b2c-lmf-radio--list {
    max-width: 992px;
    > div {
      display: flex;
      gap: 16px;
      flex-direction: column;
      > label {
        border: 1px solid rgba($c-lmf-primary, 0.3);
        flex: 1;
        border-radius: 8px;
        padding: 16px 24px;
        &.text {
          display: flex;
          justify-content: center;
          background: rgba(238, 235, 244, 0.5) !important;
          padding: 18px;
          border: 1px solid transparent;
        }
        .checkbox-container {
          display: flex;
          width: 100%;
          gap: 16px;
          align-items: center;
          .check-mark,
          .check-mark-help {
            display: flex;
            align-items: center;
          }
          .check-mark-help {
            margin-left: auto;
          }
          p {
            text-align: left;
            margin: 0;
          }
        }
        &:hover {
          box-shadow: 0 0 5px 2px rgba($c-lmf-primary, 0.3) !important;
        }
        .radio-title {
          font-weight: 600;
          font-size: 16px;
          color: $c-lmf-primary !important;
          margin-bottom: 8px;
        }
        p {
          color: $c-lmf-primary;
          line-height: 18px;
          font-size: 14px;
          font-weight: 300;
        }
        &:has(:checked) {
          background-color: $c-lmf-primary !important;
          border: 1px solid rgba($c-lmf-primary, 0.3);
          p {
            color: $c-white !important;
          }
        }
        &::before {
          background: none !important;
        }
      }
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
      background: #fff;
      display: flex;
      align-items: center;
      padding: 24px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 999;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 12px;
        .profile-card-container {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          color: $c-lmf-primary;
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
      .progress {
        background: #00b48d4d;
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        &--line {
          height: 4px;
          background: #00b48d;
          display: block;
        }
        &--indicator {
          color: #fff;
          background: #00b48d;
          border-radius: 99px;
          font-weight: bold;
          padding: 2px 4px;
          position: absolute;
          top: -10px;
          width: 50px;
          text-align: center;
        }
      }
    }
    .container {
      &.__LMF {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        flex-grow: 1;
        padding-top: 120px;
        padding-bottom: 60px;
        .modules-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          max-width: 600px;
          margin: 32px 0;
          &__header {
            background: rgba(128, 212, 220, 0.1);
            border-radius: 8px;
            padding: 16px 24px;
            display: flex;
            align-items: center;
            gap: 16px;
            color: #80d4dc;
            font-size: 14px;
          }
          &__body {
            display: flex;
            align-items: center;
            gap: 12px;
            flex-direction: column;
            &--checkbox {
              color: #5a348c;
              padding: 16px 14px;
              border-radius: 8px;
              gap: 24px;
              border: 2px solid #e2d4f4;
              flex: 1 1;
              width: 100%;
              display: flex;
              cursor: pointer;
              &:not(.checked) {
                .checked-icon {
                  display: none;
                }
              }
              &.checked {
                background: $c-lmf-primary;
                color: $c-white;
                .unchecked-icon {
                  display: none;
                }
              }
              > div {
                display: flex;
                flex-direction: column;
                gap: 2px;
                label {
                  font-weight: 800;
                  font-size: 18px;
                }
                span {
                  font-weight: 300;
                  font-size: 14px;
                }
              }
            }
          }
          &__footer {
            font-size: 12px;
            color: $c-lmf-primary;
          }
        }
        .b2c__area {
          &--content__related_persons {
            width: 100%;
            max-width: 702px;
            .beneficiary_container {
              border-radius: 12px;
              border: 2px solid $c-lmf-primary;
              padding: 16px 20px;
              label {
                font-size: 16px;
              }
              &--main {
                display: flex;
                gap: 2px;
                align-items: center;
                .question {
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  font-weight: 600;
                  color: $c-lmf-primary;
                  flex-grow: 1;
                }
                button {
                  padding: 0 28px !important;
                }
              }
              &--expand {
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                &--row {
                  display: flex;
                  align-items: center;
                  > .checkbox__wrapper,
                  > .datepicker__wrapper {
                    flex: 1;
                  }
                  .children-count {
                    background: #f8f9fa;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #e7e7e7;
                    .counter input {
                      background: transparent !important;
                      text-align: center !important;
                      font-size: 30px !important;
                      padding: 0 !important;
                      width: 60px !important;
                    }
                    button {
                      padding: 0 !important;
                      width: 40px !important;
                      height: 40px !important;
                    }
                  }
                }
                .children-birthday {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                }
              }
            }
            .beneficiary_chips {
              display: flex;
              gap: 4px;
              margin-top: 12px;
              &--button {
                background: rgba($c-lmf-primary, 0.3);
                padding: 7px 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 4px;
                font-weight: 600;
                color: $c-lmf-primary;
                svg {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .__LMF--content {
        width: 100%;
        margin: 40px 0;
        max-width: 648px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &.medium {
          max-width: 500px;
        }
        &__body {
          display: flex;
          flex-direction: column;
          gap: 12px;
          &--checkbox {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          &--container {
            border-radius: 8px;
            background: rgba(238, 235, 244, 0.5);
            display: flex;
            padding: 24px;
            flex-direction: column;
            gap: 16px;
            &__title {
              color: $c-lmf-primary;
              font-size: 16px;
              font-weight: 600;
            }
            &__body {
              display: flex;
              flex-direction: column;
              gap: 8px;
              &.rounded {
                .checkbox__wrapper {
                  font-weight: 400;
                  input {
                    border-radius: 99px;
                  }
                }
              }
              &--caption {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #ff6082;
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
        }
      }
      .__LMF--hint {
        color: $c-lmf-primary;
        max-width: 360px;
        text-align: center;
        font-size: 14px;
        &.custom {
          margin-top: 24px;
          max-width: 620px;
        }
      }
      .__LMF--link {
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
        color: $c-lmf-primary;
        font-weight: 600;
      }
      .__LMF__contact {
        align-self: end;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 80px;
        z-index: 99999;
        &--need-help {
          background: #80d4dc;
          color: #fff;
          padding: 10px 12px;
          border-radius: 8px;
          font-weight: 800;
        }
        &--img {
          background: #fff;
          border: 3px solid #80d4dc;
          border-radius: 999px;
          width: 80px;
          height: 80px;
          box-shadow: 5px 7px 0 #80d4dc;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        &--close {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

.lmf__template {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Avenir LT Std", sans-serif;
  }
}
.pricing-lmf__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid $c-lmf-primary;
  padding: 24px;
  gap: 40px;
  background: $c-white;
  text-align: center;
  position: relative;

  &__header {
    h3 {
      font-size: clamp(32px, 3vw, 24px);
      letter-spacing: -2px;
      text-align: left;
    }
  }

  &.recommended {
    background-color: $c-lmf-primary;
    gap: 30px;
    padding: 32px 24px;
    a,
    .title {
      color: $c-white;
    }
    .list {
      li {
        span {
          color: $c-white;
        }
      }
    }
    .amount,
    .currency {
      color: $c-white;
    }
  }

  .recommendation-badge {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    background: $c-lmf-yellow;
    color: $c-lmf-primary;
    font-weight: 600;
    padding: 6px 12px;
    border-radius: 5px;
    width: max-content;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  // .white {

  // }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    img {
      cursor: pointer;
    }

    .amount {
      font-size: clamp(32px, 3vw, 48px);
      font-weight: 900;
      color: $c-lmf-primary;
    }

    .currency {
      font-size: clamp(14px, 3vw, 16px);
      color: $c-lmf-primary;
      font-weight: 400;
    }
  }

  &__cta {
    background-color: $c-lmf-primary;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    margin: 15px 0;
    transition: background 0.3s ease-in-out;

    &:hover {
      background-color: darken($c-lmf-primary, 10%);
    }
  }

  &__benefits {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    &.recommended {
      margin-bottom: 60px;
    }

    .title {
      font-size: clamp(13px, 3vw, 14px);
      font-weight: 600;
      color: $c-lmf-dark-blue;
    }

    .list {
      list-style: none;
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      li {
        font-size: 0.9rem;
        padding: 5px 0;
        display: flex;
        align-items: center;
        gap: 4px;

        .icon {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          width: 100%;
          font-size: clamp(12px, 3vw, 13px);
          font-weight: 500;
          color: $c-lmf-dark-blue;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    width: 100%;

    span {
      display: block;
      color: $c-lmf-blue;
      text-decoration: none;
      font-weight: 400;
      font-size: clamp(12px, 3vw, 13px);
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
    &.recommended {
      span {
        color: $c-white;
      }
    }
  }

  .name__color--yellow {
    color: $c-lmf-secondary;
  }
  .name__color--red {
    color: $c-lmf-dark-red;
  }
  .name__color--pink {
    color: $c-lmf-red;
  }
  .name__color--blue {
    color: $c-lmf-pale-blue;
  }
  .name__color--white {
    color: $c-white;
  }
  .name__color--primary {
    color: $c-lmf-primary;
  }
}

//
.button__lmf {
  display: flex;
  justify-content: flex-start;

  .ant-btn {
    width: 100%;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

.lmf_primary {
  background-color: $c-lmf-primary;
  color: $c-white !important;
  border: 2px solid $c-lmf-primary;

  &:disabled {
    border-color: none !important;
    color: $c-white !important;
    background-color: $c-lmf-primary !important;
    opacity: 0.3 !important;
  }

  &:hover {
    background-color: darken($c-lmf-primary, 10%);
    color: $c-white;
    border: 2px solid $c-lmf-primary !important;
  }
}

.lmf_secondary {
  background-color: $c-lmf-secondary;
  color: $c-white !important;
  border: none;

  &:hover {
    background-color: darken($c-lmf-secondary, 10%);
    color: $c-white !important;
  }
}

.lmf_white {
  background-color: $c-white;
  color: $c-lmf-primary;
  border: 2px solid $c-lmf-primary;

  &:disabled {
    border-color: $c-lmf-primary !important;
    color: $c-lmf-primary !important;
    background-color: $c-white !important;
    opacity: 0.3 !important;
  }

  &:hover {
    color: $c-white !important;
    background-color: $c-lmf-primary !important;
    border: 2px solid $c-white !important;
  }
}

.lmf_default {
  background-color: $c-white;
  color: $c-lmf-primary;
  border: none;

  &:hover {
    background-color: lighten($c-white, 5%);
  }
}

.lmf_gray {
  background-color: $c-lmf-gray;
  color: $c-white;
  border: none;

  &:hover {
    background-color: darken($c-lmf-gray, 10%);
  }
}

.lmf_danger {
  background-color: #ff5a93;
  color: $c-white;
  border: none;

  &:hover {
    background-color: darken(#ff5a93, 10%);
  }
}

.lmf_success {
  background-color: #41ad6a;
  color: $c-white;
  border: none;

  &:hover {
    background-color: darken(#41ad6a, 10%);
  }
}

/* Button Variants */

.lmf_bordered {
  background: transparent !important;
  border: 2px solid currentColor;
  color: inherit;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.lmf_large {
  padding: 16px 32px;
  height: auto;
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 600;
}

.lmf_medium {
  padding: 8px 28px;
  height: auto;
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 600;
}
.lmf_middle {
  padding: 8px 24px;
  height: auto;
  font-size: clamp(14px, 3vw, 16px);
  font-weight: 600;
}
//
// Swiper LMF
.lmf__swiper {
  max-width: 1080px;
  .swiper-slide {
    width: 100%;
  }

  .swiper-wrapper {
    align-items: center !important;
    width: 100%;
    height: auto;
    position: relative;
    padding: 24px 0;
  }

  .lmf_swiper__arrows {
    margin-top: 32px;
    position: relative;
    width: max-content;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 16px;
    border: 1.5px solid rgba($c-lmf-primary, 1);
    border-radius: 8px;

    .swiper-button-next,
    .swiper-button-prev {
      height: 40px;
      width: 56px;
      background-color: transparent;
      background-color: rgba($c-lmf-primary, 1);
      color: $c-white;
      cursor: pointer;
      z-index: 1;
      position: static;
      margin: auto 0;

      &.swiper-button-disabled {
        background-color: transparent;
        opacity: 1;

        &::after {
          color: rgba($c-lmf-primary, 1);
        }
      }

      &::after {
        color: $c-white;
        font-size: 16px;
        font-weight: 800;

        @media (width <=991px) {
          font-size: 14px;
        }
      }
    }

    .swiper-button-prev {
      left: unset;
      content: none;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .swiper-button-next {
      right: unset;
      content: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }
}
