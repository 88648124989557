.card-container {
  background: $c-white;
  border-radius: 8px;
  padding: 24px 24px;
}

.card-header {
  padding: 0 0 12px 0;
}

.card-body {
  padding: 16px 0;
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0;
  color: $c-text;
}

.card-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $c-text-sec;
  margin-top: 8px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $c-border;
}

.card__wrapper {
  background: $c-white;
  border-radius: 8px;
  padding: 24px;

  &--head {
    padding-bottom: 16px;
    border-bottom: 1px solid $c-border;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
    &--title {
      font-weight: 600;
      font-size: clamp(16px, 3vw, 20px);
      line-height: clamp(24px, 3vw, 38px);
      margin-bottom: 0;
    }
    &--subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $c-text-sec;
      margin-top: 0px;
    }

    &--actions {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;
    }
  }

  @media (max-width: 991px) {
    padding: 16px;
  }
}


.card__toggle {
  background: $c-white;
  border-radius: 8px;
  padding: 24px;

  &--head {
    padding-bottom: 16px;
    border-bottom: 1px solid $c-border;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .--title {
      font-weight: 600;
      font-size: clamp(16px, 3vw, 20px);
      line-height: clamp(24px, 3vw, 38px);
      margin-bottom: 0;
    }
    .--subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $c-text-sec;
      margin-top: 0px;
    }

    &--actions {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;
    }
  }

  &--icon {
    height: 32px;
    width: 32px;
    background-color: $c-background;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 991px) {
    padding: 16px;
  }
}

.card__custom {
  background: $c-white;
  border-radius: 8px;
  padding: 24px;

  max-width: 991px;
  margin: auto;

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  &--head {
    padding-bottom: 16px;
    // border-bottom: 1px solid $c-border;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 2px;
    &--title {
      font-weight: 700;
      font-size: clamp(16px, 3vw, 20px);
      line-height: clamp(24px, 3vw, 32px);
      margin-bottom: 0;
    }
    &--subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $c-text-sec;
      margin-top: 0px;
    }

    &--actions {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;
    }
  }

  &.card--full {
    max-width: 100%;
  }

  &.card--medium {
    max-width: 991px;
    margin: auto;
  
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &.card--small{
    max-width: 767px;
    margin: auto;
  
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &.card--primary {
    border: 2px solid $c-primary;
  }
  &.card--bordered {
    border: 1px solid rgb(209, 213, 219);
  }
  &.card--shadow {
    border: 1px solid rgb(209, 213, 219);

    &:hover {
      box-shadow: 0 0 16px 0px #ececec;
    }
  }

  &.card--transparent {
    background: transparent !important;
    padding: 16px 0;
  }

  @media (max-width: 991px) {
    padding: 16px;
  }
}

.card__count {
  background: $c-white;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &--content {
    display: flex;
    flex-direction: column;
  }

  &--icon {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($c-primary, 0.08);
    border-radius: 8px;

    & * {
      color: $c-primary;
      font-size: 24px;
    }
  }

  &--label {
    font-weight: 400;
    font-size: clamp(12px, 3vw, 14px);
    line-height: clamp(20px, 3vw, 24px);
    margin-bottom: 0;
  }
  &--value {
    font-weight: 500;
    font-size: clamp(16px, 3vw, 24px);
    line-height: clamp(24px, 3vw, 32px);
    margin-top: 0px;
  }

  &--action {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $c-primary;
    cursor: pointer;

    .label {
      border-bottom: 1px solid rgba($c-primary, 1);
      padding: 2px 0;
    }
  }

  @media (max-width: 991px) {
    padding: 16px;
  }
}

.product--selected {
  width: 100%;
  margin-top: 8px;
  & .product-info__img {
    height: 80px;
  }
}

.icon {
  object-fit: contain;
  max-height: 100px;
  max-width: 200px;
}
