.activities__log {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 16px;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 100%;
    border-left: 1px solid rgba($c-gray-light, 1);
    transform: translateX(-50%);
    z-index: 0;
  }

  &--item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    position: relative;
    .icons {
      height: 40px;
      width: 40px;
      border-radius: 100px;
      background: rgba($c-primary, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $c-primary;
      font-size: clamp(10px, 3vw, 12px);
      font-weight: 600;
      text-transform: uppercase;
    }

    .icon {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: rgba($c-red-light2, 1);
      color: $c-red;
      border: 4px solid rgba($c-white, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      position: absolute;
      left: 16px;
      transform: translateX(-50%);
      z-index: 2;
    }

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      flex-grow: 1;
      padding-left: 48px;
      padding-top: 4px;

      .info {
        display: flex;
        flex-direction: column;
        gap: 0px;
        color: $c-black;
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 300;

        .status {
          color: $c-black;
          font-size: clamp(12px, 3vw, 14px);
          font-weight: 500;
        }
        .name {
          color: $c-primary;
          font-size: clamp(12px, 3vw, 12px);
          font-weight: 600;
        }
      }

      .date {
        color: $c-gray;
        font-size: clamp(12px, 3vw, 12px);
        font-weight: 300;
      }
    }
  }

  .relaunch {
    background: rgba($c-light-primary, 1);
    color: $c-primary;
  }

  .red {
    background: rgba($c-red-light2, 1);
    color: $c-red;
  }
  .primary {
    background: $c-primary-background;
    color: $c-primary;
  }
}

.no-activity {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-gray;
  padding: 20px;
  text-align: center;
}

// dashboard

.activities__area {
  height: 560px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 8px;
  scroll-behavior: smooth;

  .grouped--date {
    color: $c-gray-dark;
    font-weight: 600;
    font-size: clamp(12px, 3vw, 14px);
    padding: 16px 0;
  }

  .log--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 8px 0;

    .w-32 {
      width: 32%;
    }
    .w-25 {
      width: 25%;
    }
    .w-8 {
      width: 8%;
    }

    .log--time {
      display: flex;
      align-items: center;
      color: $c-gray;
      gap: 4px;

      span {
        font-size: clamp(12px, 3vw, 12px);
      }
    }

    .log--status {
      display: flex;
      align-items: center;
      color: $c-dark;
      background-color: rgba($c-gray-light, 0.8);
      padding: 4px 12px;
      border-radius: 100px;
      width: fit-content;
      span {
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 400;
      }
    }

    .log--user {
      color: $c-gray;
      font-weight: 600;
      font-size: clamp(12px, 3vw, 14px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .log--type {
      font-size: clamp(12px, 3vw, 14px);
      font-weight: 600;
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      gap: 8px;
      padding: 4px 12px;
      border-radius: 8px;
      color: rgba($c-primary, 1);
  
    
  
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border-radius: 100px;

        &.true {
          background-color: rgba($c-red, 0.1);

        }
    
        &.false {
          background-color: rgba($c-primary, 0.1);
        }
      }
    }
  }
}

.notes__area {
  width: 100%;
  margin: 0 auto;

  h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    text-align: center;
  }

  &--add {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 16px 0;

    .input__wrapper {
      flex-grow: 1;
    }
  }

  &--list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;

    .item {
      padding: 0px 0 16px 0;
      width: 100%;
      border-bottom: 1px solid rgba($c-gray, 0.2);

      .content {
        display: flex;
        align-items: center;
        gap: 8px;

        .row {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          flex-grow: 1;

          .icon {
            height: 32px;
            width: 32px;
            border-radius: 100px;
            background: rgba($c-primary, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $c-primary;
            font-size: clamp(10px, 3vw, 12px);
            font-weight: 600;
            text-transform: uppercase;
          }

          .note {
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex-grow: 1;
            width: 80%;

            p {
              font-size: clamp(12px, 3vw, 14px);
              line-height: clamp(14px, 3vw, 18px);
              font-weight: 400;
              color: $c-dark;
            }
            small {
              font-size: clamp(10px, 3vw, 12px);
              font-weight: 300;
              color: $c-gray;
              span {
                font-size: clamp(12px, 3vw, 12px);
                font-weight: 500;
                color: $c-primary;
              }
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 2px;

          .btn {
            height: 28px;
            width: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .note-item {
    background: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 4px;
    position: relative;

    p {
      margin: 0 0 5px 0;
      font-size: 16px;
      color: #333;
    }

    small {
      display: block;
      margin-bottom: 10px;
      font-size: 12px;
      color: #888;
    }

    button {
      padding: 6px 10px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        background-color: #218838;
      }

      &.delete-btn {
        background-color: #dc3545;

        &:hover {
          background-color: #c82333;
        }
      }

      &.edit-btn {
        background-color: #ffc107;

        &:hover {
          background-color: #e0a800;
        }
      }
    }

    .edit-mode {
      textarea {
        width: 100%;
        height: 60px;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
          border-color: #007bff;
          outline: none;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;

        button {
          padding: 6px 10px;
          margin-left: 10px;
          cursor: pointer;

          &:first-child {
            background-color: #28a745;
          }

          &:last-child {
            background-color: #6c757d;

            &:hover {
              background-color: #5a6268;
            }
          }
        }
      }
    }
  }
}
