.search--bar {
  display: flex;
  gap: 16px;
  > * {
    flex: auto;
  }

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }
  @media (max-width: 520px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  :where(.css-dev-only-do-not-override-d2lrxs).ant-input-outlined:hover {
    background: $c-background;
  }
  .ant-input {
  }
}

.filter__area {
  display: grid;
  grid-template-columns: 1fr 30%;
  grid-gap: 16px;

  @media (max-width: 1124px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.filter__search {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  .select__wrapper
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: $c-white !important;
    border: 1px solid $c-primary;
  }
  .ant-select-selection-item {
    color: $c-primary;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-select .ant-select-arrow {
    color: $c-primary;
    font-weight: 600;
    font-size: 12px;
  }
}
