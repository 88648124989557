.button--wrapper button {
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.ant-btn:has(.primary) {
    background: $c-blue-light2;
    color: $c-white;
    border: 1px solid $c-blue-light2;

    &:hover,
    &:active {
      color: $c-white;
      border-color: $c-blue-light3;
      filter: brightness(1.1);
    }

    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background: $c-blue-light3;
      color: $c-blue-light2;
      border: 1px solid $c-blue-light3;
      opacity: 0.4;
    }
  }

  &.text {
    background: transparent;
    color: $c-gray-light4;
    border: none;
    box-shadow: none;

    &:hover,
    &:active {
      color: $c-gray-light4;
      border: none;
    }

    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background: $c-gray-light6;
      color: $c-gray-light4;
      border: 1px solid $c-gray-light6;
      opacity: 0.4;
    }
  }

  &.default {
    background: transparent;
    color: $c-gray-light4;
    border: 1px solid $c-gray-light3;

    &:hover,
    &:active {
      color: $c-gray-light4;
      border: 1px solid $c-gray-light3;
    }

    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background: transparent;
      color: $c-gray-light4;
      border: 1px solid $c-gray-light3;
      opacity: 0.4;
    }
  }
}

.button__wrapper {
  display: flex;
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.left {
    justify-content: start;
  }

  button {
    padding: 0 16px;
    height: 40px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 4px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    border-color: none !important;
  }

  .primary {
    background-color: $c-primary;
    color: $c-white;
    &:hover {
      color: $c-white !important;
      background-color: darken($c-primary, 8%) !important;
      border: none !important;
    }
    &:disabled {
      opacity: 0.4;
    }
  }

  .secondary {
    background-color: $c-secondary;
    color: $c-white;

    &:hover {
      color: $c-white !important;
      background-color: lighten($c-secondary, 8%) !important;
      border: none !important;
    }
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-secondary;
      color: $c-white;
      opacity: 0.3;
    }
  }

  .secondary--link {
    background-color: transparent;
    color: $c-gray;
    box-shadow: none;
    padding: 0 8px !important;

    &:hover {
      color: $c-text !important;
      background-color: transparent;
      border: none !important;
    }
  }

  .secondary--normal {
    background-color: transparent;
    color: $c-text;
    box-shadow: none;
    padding: 0 8px !important;

    &:hover {
      color: $c-primary !important;
      background-color: transparent;
      border: none !important;
    }
  }

  .primary--light {
    background-color: $c-primary-light;
    color: $c-primary;

    &:hover {
      color: $c-white !important;
      background-color: $c-primary !important;
      border: none !important;
    }
  }

  .secondary--light {
    background-color: $c-background;
    color: $c-text;

    &:hover {
      color: $c-white !important;
      background-color: $c-text;
      border: none !important;
    }
  }

  .primary--link {
    background-color: transparent;
    color: $c-primary;
    box-shadow: none;

    &:hover {
      background-color: transparent;
      border: none !important;
    }
  }
  .primary--underline {
    background-color: transparent;
    color: $c-primary;
    box-shadow: none;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: $c-primary;
    text-underline-offset: 4px;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      border: none !important;
    }
  }

  .primary--outlined {
    background-color: transparent;
    color: $c-primary;
    border: 1px solid $c-primary;

    &:hover {
      background-color: $c-white;
      border: 1px solid $c-primary;
    }
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-white;
      border: 1px solid $c-primary;
      opacity: 0.3;
    }
  }

  &.ant-btn:has(.primary--outlined) {
    background-color: transparent;
    border: 1px solid $c-primary !important;
    color: $c-primary;
    box-shadow: none;

    &:hover {
      color: $c-gray !important;
      border: 1px solid $c-gray !important;
      background-color: transparent;
      border: 1px solid $c-gray !important;
      color: $c-gray;
      box-shadow: none;

      &:hover {
        color: $c-black !important;
        border: 1px solid $c-gray !important;
        background-color: transparent;
      }
    }
  }
  .default {
    background-color: $c-white;
    color: $c-text;
    box-shadow: none;

    &:hover,
    &:disabled {
      color: $c-gray !important;
      background-color: transparent;
      border: none !important;
    }
  }

  .danger {
    background-color: $c-red;
    color: $c-white;
    box-shadow: none;

    &:hover,
    &:disabled {
      color: $c-white !important;
      background-color: lighten($c-red, 8%) !important;
      border: none !important;
    }
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background: $c-red-light;
      opacity: 0.4;
    }
  }

  .danger--light {
    background-color: rgba($c-danger, 0.1);
    color: $c-danger;

    &:hover {
      color: $c-white !important;
      background-color: $c-danger !important;
      border: none !important;
    }
  }

  .danger--link {
    background-color: transparent;
    color: $c-danger;
    box-shadow: none;
    padding: 0;

    &:hover {
      color: $c-red !important;
      background-color: transparent !important;
      border: none !important;
    }
  }

  .danger--outlined {
    background-color: transparent;
    color: $c-danger;
    border: 1px solid $c-danger;

    &:hover {
      background-color: $c-white;
      border: 1px solid $c-danger;
      color: rgba($c-danger, 1);
    }
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-white;
      border: 1px solid $c-danger;
      opacity: 0.3;
      color: rgba($c-danger, 1);
    }
  }

  .success {
    background-color: $c-green;
    color: $c-white;
    box-shadow: none;

    &:hover {
      color: $c-white !important;
      background-color: $c-green-light;
      border: none !important;
    }
  }

  .success--light {
    background-color: rgba($c-success, 0.1);
    color: $c-success;

    &:hover {
      color: $c-white !important;
      background-color: $c-success;
      border: none !important;
    }
  }

  .success--link {
    background-color: transparent;
    color: $c-success;
    box-shadow: none;

    &:hover {
      color: $c-green;
      background-color: transparent !important;
      border: none !important;
    }
  }

  .black {
    background-color: $c-secondary;
    color: $c-white;
    box-shadow: none;

    &:hover {
      background-color: $c-black;
      color: $c-white !important;
      border: none !important;
    }
  }

  .gray {
    background-color: $c-gray;
    color: $c-white;
    box-shadow: none;

    &:hover {
      color: $c-text !important;
      background-color: $c-gray-light;
      border: none !important;
    }
  }

  .gray--link {
    background-color: transparent;
    color: $c-gray;
    box-shadow: none;

    &:hover {
      background-color: transparent;
      border: none !important;
    }
  }

  .gray--outlined {
    background-color: transparent;
    color: $c-primary;
    border: 1px solid $c-primary;

    &:hover {
      background-color: $c-white;
      border: 1px solid $c-primary;
    }
    &[disabled],
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $c-white;
      border: 1px solid $c-primary;
      opacity: 0.3;
    }
  }

  .gray--underline {
    background-color: transparent;
    color: $c-gray;
    box-shadow: none;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: $c-gray;
    text-underline-offset: 4px;

    &:hover {
      background-color: transparent;
      border: none !important;
    }
  }

  .warning {
    background-color: $c-btn-warning;
    color: $c-text;
    box-shadow: none;

    &:hover {
      color: $c-text !important;
      border: none !important;
      filter: brightness(0.9);
    }
  }

  .warning--light {
    background-color: rgba($c-btn-warning, 0.1);
    color: $c-btn-warning;

    &:hover {
      color: $c-white !important;
      background-color: $c-btn-warning;
      border: none !important;
    }
  }

  .warning--link {
    background-color: transparent;
    color: $c-btn-warning;
    box-shadow: none;

    &:hover {
      color: $c-orange;
      background-color: transparent !important;
      border: none !important;
    }
  }

  .gray--outlined {
    background-color: transparent;
    border: 1px solid $c-gray !important;
    color: $c-text-sec;
    box-shadow: none;

    &:hover {
      color: $c-gray !important;
      border: 1px solid $c-gray !important;
      color: $c-gray;
    }
  }

  :where(.css-dev-only-do-not-override-byeoj0).ant-btn:not(
      :disabled
    ):focus-visible {
    outline: none !important;
  }

  .full--width {
    width: 100%;
  }
}
