.expiration_modal {
  display: flex;
  flex-direction: column;
  &--icon {
    color: #d43c1a;
    font-weight: 600;
  }
  &--title {
    font-weight: 800;
    font-size: 20px;
  }
  &--description {
    font-weight: 500;
    margin-bottom: 8px;
  }
  &--date {
    margin: 8px 0;
  }
  &--actions {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-self: flex-end;
  }
}
.contact-form {
  max-width: 991px;
  margin: auto;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
  &__content {
    border: 1px solid rgb(209, 213, 219);
    border-radius: 10px;
    background: #fff;
    padding: 32px 24px;
    .ant-row {
      flex-direction: column;
      row-gap: 40px !important;
    }
  }
  &__action {
    margin-top: 24px;
    &--submit {
      float: right;
      padding: 8px 24px;
      background: rgba(14, 147, 229, 0.1);
      color: #0e93e5;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      transition: 0.3s all ease;
      border: none;
      &:hover {
        box-shadow: 0 0 5px 2px #91cdf3;
      }
    }
  }
}

.form-container {
  // background: $c-white;
  // border-radius: 16px;
  // padding: 8px;

  & .title {
    color: $c-black;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
  }
}

.form-vertical--wrapper {
  & .form-vertical--title {
    &--counter {
      align-items: center;
      background: #0e93e51a;
      border-color: #d1d5db;
      border-radius: 100px;
      color: #0e93e5;
      display: flex;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      margin-right: 16px;
      width: 24px;
    }

    & h3 {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      .label__required {
        color: $c-danger;
      }
    }

    & span {
      font-weight: 400;
      font-size: 12px;
      color: $c-gray-light4;
    }
  }

  & .form-vertical--content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid $c-gray-light3;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 16px;
  }
}

.form-highlight--wrapper {
  background: $c-background;
  border-radius: 8px;
  padding: 0 16px;

  & .form-highlight--title {
    & h3 {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }

    & span {
      font-weight: 400;
      font-size: 12px;
      color: $c-gray-light4;
    }
  }

  & .form-highlight--content {
    border-radius: 8px;
    padding: 16px 0;
  }

  & .form-highlight--head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $c-gray-light;
    padding: 12px 0;
  }
}

.wrapper--forms {
  display: flex;
  gap: 16px;
  flex-direction: column;
  flex-grow: 1;
}
