.filter__container {
  position: relative;
  display: flex;
  flex-direction: column;
  > .button__wrapper {
    > button {
      width: 100%;
      justify-content: space-between;
    }
  }

  .row {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &--dropdown {
    left: 0;
    top: 46px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    z-index: 9;
    position: absolute;
    display: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    @media (max-width: 1560px) {
      right: 0;
      left: initial;
    }
    width: max-content;
    &.expanded {
      display: block;
    }
    > Input {
      width: 100%;
    }
    &__list {
      ul {
        margin: 12px 0;
        list-style: none;
        > li {
          padding: 10px 0;
          border-bottom: 1px solid $c-light-border;
          &.hide {
            display: none !important;
          }
          &.selected {
            > label {
              font-weight: 600;
              color: $c-primary;
            }
          }
          > label {
            text-transform: capitalize;
            color: $c-gray;
            width: 100%;
            font-weight: 400;
            line-height: 13px;
          }
        }
      }
    }
    &__actions {
      display: flex;
      justify-content: space-between;
    }
  }
  &--result {
    display: flex;
    flex-flow: wrap;
    gap: 4px;
    padding-top: 12px;
    span {
      padding: 4px 8px;
      color: $c-primary;
      background: $c-primary-light;
      font-weight: 600;
      border-radius: 99px;
    }
  }
}
