.title-with-icon-container {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: $c-text;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  &:has(span:empty) {
    display: none;
  }
}

.title {
  color: $c-text;
  font-size: 14px;
  font-weight: 600;
}

.subtitle {
  color: $c-text-sec;
  font-size: 12px;
  font-weight: 400;
}
