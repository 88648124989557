//
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Aptos";
  src: url("../../assets/fonts/Aptos/Aptos-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdBlack.otf")
    format("opentype");
  font-weight: 900; // Black
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdHeavy.otf")
    format("opentype");
  font-weight: 800; // Heavy
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdLight.otf")
    format("opentype");
  font-weight: 300; // Light
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdMedium.otf")
    format("opentype");
  font-weight: 500; // Medium
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdOblique.otf")
    format("opentype");
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdRoman.otf")
    format("opentype");
  font-weight: 400; // Roman (Normal)
}
// @font-face {
//   font-family: "Avenir LT Std";
//   src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdBlackOblique.otf") format("opentype");
//   font-weight: 900;
//   font-style: oblique;
// }

// @font-face {
//   font-family: "Avenir LT Std";
//   src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdBook.otf") format("opentype");
//   font-weight: 400; // Regular
// }

// @font-face {
//   font-family: "Avenir LT Std";
//   src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdBookOblique.otf") format("opentype");
//   font-weight: 400;
//   font-style: oblique;
// }

// @font-face {
//   font-family: "Avenir LT Std";
//   src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdHeavyOblique.otf") format("opentype");
//   font-weight: 800;
//   font-style: oblique;
// }

// @font-face {
//   font-family: "Avenir LT Std";
//   src: url("../../assets/fonts/AvenirLTStd/AvenirLTStdMediumOblique.otf") format("opentype");
//   font-weight: 500;
//   font-style: oblique;
// }

@import "../components/header";

@import url("https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css");

:root {
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
}

#root {
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 10px;
  margin-inline-end: 0;
}

body {
  background-color: $c-background;
  color: $c-text;
  font-family: "Public Sans", sans-serif;
  overflow-x: hidden;
}

.ant-layout {
  background-color: $c-background;
  color: $c-text;
  height: 100%;
}

.children--layout {
  padding-bottom: 24px;
  @media (max-width: 1391px) {
    padding-bottom: 80px;
  }
}

.center--content {
  min-height: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center--content--col {
  width: 60%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 32px;

  .hide {
    display: none !important;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 991px) {
    width: 90%;
  }
}

.hidden {
  display: none !important;
}
