.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.supporting--modal {
  width: 50%;

  @media (max-width: 991px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
}

.custom__modal {
  width: 40% !important;

  @media (max-width: 1199px) {
    width: 60% !important;
  }

  @media (max-width: 991px) {
    width: 80% !important;
  }

  @media (max-width: 600px) {
    width: 95% !important;
  }

  .ant-modal-content {
    background-color: white;
    border-radius: 16px;
  }

  .ant-modal-header {
    .ant-modal-title {
      font-size: clamp(18px, 3vw, 24px);
    }
  }

  .ant-modal-body {
    .input__wrapper {
      width: 100%;
    }

    .btn--grow {
      .button__wrapper {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }

  .ant-modal-close {
    color: $c-gray2;
  }

  .footer {
    margin-top: 24px;
  }
}

.custom--mini--modal {
  .ant-modal-body {
    padding: 24px 0 0;
  }

  .ant-modal-content {
    background-color: $c-dark-2;
    border-radius: 8px;
  }

  .ant-modal-close {
    color: $c-gray;

    .ant-modal-close-x {
      width: 50px;
      height: 40px;
    }
  }

  .footer {
    margin-top: 24px;
  }
}

.options--modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    text-align: center;
  }

  .options__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 1rem;

    .option__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 2rem;
      border-radius: 8px;
      border: 1px solid $c-purple2;
      background-color: $c-white;
      width: 200px;
      height: 180px;
    }

    .selected {
      background-color: $c-main;
    }

    img {
      height: 50px;
      width: 50px;
    }
  }
}

.modal__container {
  @media (width <= 520px) {
    width: 94% !important;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal {
    &-content {
      border-radius: 8px;
      padding: 16px;
    }

    &-header {
      margin-bottom: 0;
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        color: $c-text;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }

      &:has(span:empty) {
        display: none;
      }
    }

    &-body {
      padding: 16px 0;
    }

    &-footer {
      margin-top: 0;
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @media (width <= 520px) {
        flex-direction: column-reverse;
        gap: 8px;

        .button__wrapper {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px 0;

    h3 {
      font-size: clamp(14px, 3vw, 18px);
      text-align: center;
      font-weight: 600;
    }
  }

  .footer {
    margin-top: 24px;
  }
}

.custom__drawer {
  .ant-drawer-header-title {
    flex-flow: row-reverse;
  }
}

.modal__xlsx {
  width: 100% !important;
  .ant-modal-header {
    padding-bottom: 24px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > * {
      flex-grow: 1;
      width: 100%;
    }
  }

  .wrapper__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &--row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 8px 16px;
      border-radius: 8px;
      gap: 8px;
      overflow-x: scroll;
      border: 1px solid rgba($c-main, 0.1);

      .item {
        flex-grow: 1;
        width: 20%;
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .gender {
        width: 60px;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 50px;
      }

      &.duplicate {
        background-color: rgba($c-red, 0.02);
        border: 1px solid rgba($c-red, 0.5);
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;
  }
  .upload__area {
    width: 100%;
    .ant-upload-select {
      width: 100%;
      .ant-upload {
        padding: 32px;
        width: 100%;
        border: 1px dashed $c-primary;
        background-color: rgba($c-primary, 0.06);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        cursor: pointer;
      }

      label {
        font-size: clamp(14px, 3vw, 18px);
        color: $c-primary;
        font-weight: 500;
      }
    }
  }
}
