.main__template {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  
  @media (max-width: 1391px) {
    gap: 0;
  }

  &--body {
    width: 100%;
    padding: 0 16px;
  }

}
