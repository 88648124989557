//Setup
@import "partials/variables";
@import "partials/colors";
@import "partials/spacing";
@import "partials/helpers";

//Base styles
@import "base/base";

//Component Styles
@import "components/layout";
@import "components/sidebar";
@import "components/header";
@import "components/heading";
@import "components/sizing";
@import "components/button";
@import "components/avatar";
@import "components/profile";
@import "components/select";
@import "components/input";
@import "components/radio";
@import "components/spin";
@import "components/stepper";
@import "components/form";
@import "components/description";
@import "components/box";
@import "components/chart";
@import "components/tab";
@import "components/alert";
@import "components/table";
@import "components/label";
@import "components/modal";
@import "components/slider";
@import "components/auto-complete";
@import "components/search";
@import "components/drawer";
@import "components/popover";
@import "components/card";
@import "components/breadcrumb";
@import "components/progress";
@import "components/switch";
@import "components/quote";
@import "components/status";
@import "components/dropdown";
@import "components/text-editor";
@import "components/project";
@import "components/title-with-icon";
@import "components/cost";
@import "components/filter";
@import "components/grid";
@import "components/empty";
@import "components/guarantee";
@import "components/format";
@import "components/composant";
@import "components/subscription";
@import "components/swiper";
@import "components/notification";
@import "components/activities";
@import "components/smartphonewidget";

// Pages Style
@import "screens/dashboard";
@import "screens/home";
@import "screens/_templates.product.comparison";
@import "screens/_templates.product.filters";
@import "screens/_templates.product.list";
@import "screens/_templates.contact";
@import "screens/_templates.subscription";
@import "screens/_templates.fast";
@import "screens/_templates.select-products";
@import "screens/_templates.quote";
@import "screens/_client";
@import "screens/_reminder";
@import "screens/_interactions";
@import "screens/qualifications";
@import "screens/_not.found";
@import "screens/auth";
@import "screens/commission.statements";
@import "screens/coverage";
@import "screens/_process";
@import "screens/_process_radiance";
@import "screens/_user";
@import "screens/_contact";
@import "screens/templates";
@import "screens/solyon.client";
@import "screens/lmf.client";

// Carousel Gallery

// SCROLL
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba($c-gray, 0.1);
  border: 0px none #ffffff;
}
// ::-webkit-scrollbar-thumb:hover {
//   background: #ffffff;
// }
::-webkit-scrollbar-thumb:active {
  background: rgba($c-gray, 0.2);
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
}
// ::-webkit-scrollbar-track:hover {
//   background: #1A2B48;
// }
::-webkit-scrollbar-track:active {
  background: rgba($c-gray, 0.2);
}
::-webkit-scrollbar-corner {
  background: transparent;
}
