.label__area {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  .label__optional {
    font-style: italic;
    color: $c-gray-light4;
  }

  .label__sub {
    font-size: 12px;
    font-weight: 400;
    padding-left: 4px;
  }

  .label__icon {
    display: flex;
  }
}

.hide--icon:empty,
.hide--label:empty,
.hide--label {
  display: none !important;
}

.text-sec {
  color: $c-text-sec;
}
