.switch__wrapper {
  & button {
    height: 24px;

    .ant-switch-inner {
      font-weight: 500;
      color: $c-background;
      font-size: 16px;
      padding-inline-start: 28px;
      padding-inline-end: 10px;
    }
    .ant-switch-handle {
      width: 20px;
      height: 20px;
      &::before {
        background: $c-primary !important;
        border-radius: 100px !important;
      }
    }
    &.ant-switch-checked {
      background: $c-primary;
      .ant-switch-inner {
        color: $c-white;
      }
      .ant-switch-handle {
        left: calc(100% - 22px);
        &::before {
          background: $c-white !important;
        }
      }
    }

    &.ant-switch-checked:hover {
      background: $c-primary !important;
      .ant-switch-inner-checked {
        color: $c-white !important;
      }
    }
    &:hover {
      background: $c-background !important;
      .ant-switch-inner {
        .ant-switch-inner-unchecked {
          color: $c-primary !important;
        }
      }
    }
  }
}

// Old
.button__switch {
  height: 24px;
  background: $k-filled_input;
  .ant-switch-inner {
    font-weight: 500;
    color: $k-gray-600;
    font-size: 16px;
    &-unchecked {
      color: $c-primary !important;
    }
  }
  .ant-switch-handle {
    width: 20px;
    height: 20px;
    &::before {
      background: $k-bg-primary !important;
      border-radius: 100px !important;
    }
  }
  &.ant-switch-checked {
    background: $k-bg-primary;
    .ant-switch-inner {
      color: $c-white;
    }
    .ant-switch-handle {
      left: calc(100% - 22px);
      &::before {
        background: $c-white !important;
      }
    }
  }

}
