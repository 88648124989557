.access-cell {
  display: flex;
  text-align: center;
  gap: 4px;
  color: #0e93e5;
  cursor: pointer;
}

.sign-project {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 12px;
    .divider {
      height: 1px;
      width: 100%;
      background: $c-border;
    }
  }
  &__attachments {
    p {
      font-size: 14px;
      font-weight: 600;
    }
    button {
      font-size: 12px;
    }
    .ant-upload-wrapper .ant-upload-select {
      width: 100%;
    }
    &--list {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      width: 100%;
      &--left {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: $c-text-sec;
      }
      &--delete {
        justify-self: end;
        cursor: pointer;
      }
    }
    &--title {
      h4 {
        font-size: 16px;
        font-weight: 700;
      }
      span {
        color: $c-text-sec;
        font-size: 12px;
      }
    }
  }
}

.sign-project-recap {
  &__success {
    display: flex;
    gap: 8px;
    align-items: center;
    color: $c_success;
    margin-bottom: 24px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid $c_border;
    padding: 24px;
  }
  &__top {
    display: flex;
    flex-direction: space-between;
    gap: 16px;
  }
  &__logo {
    flex: 1;
    img {
      height: 56px;
      object-fit: contain;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &--title {
      display: flex;
      gap: 4px;
      align-items: baseline;
      font-size: 24px;
      font-weight: 600;
    }
    &--status {
      align-self: end;
      font-size: 12px;
      font-weight: 600;
      background: $c_primary_background;
      padding: 4px 12px;
      border-radius: 32px;
      color: $c-primary;
    }
    &--creation {
      font-size: 12px;
      color: $c_text_sec;
    }
  }
  &__price {
    &--monthly {
      font-size: 32px;
      font-weight: 700;
      color: $c-primary;
      span {
        font-size: 18px;
        font-weight: 500;
        color: $c-text-sec;
      }
    }
    &--yearly {
      font-size: 14px;
      color: $c-text;
      span {
        color: $c-text-sec;
      }
    }
  }
}
.offer__drawer {
  .ant-drawer-header-title {
    flex-flow: row-reverse;
  }
  &--container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .offer__card {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    display: flex;
    gap: 16px;
    flex-direction: column;
    &--header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h1 {
        font-size: 18px;
        font-weight: 800;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: $c-gray;
      }
    }
    .button__wrapper {
      justify-content: end;
    }
  }
}

.risk__cards {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &--item {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid rgba($c-gray, .4);
    border-radius: 12px;
    transition: background ease-in .2s;
    background-color: $c-white;
    cursor: pointer;

    span {
      font-size: clamp(14px, 3vw, 20px);
      font-weight: 600;
      color: $c-dark;
    }

    .icon {
      background-color: $c-background;
      color: $c-text;
      padding: 16px;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      gap: 4px;
      transition: background ease-in .2s;
    }

    &:hover {
      border-color: transparent;
      transition: background ease-in .2s;
      background-color: rgba($c-background, .6);

      .icon {
        transition: background ease-in .2s;
        background-color: rgba($c-dark, .9);
        color: $c-white;
      }
    }

  }

}


.latset__projects {
  display: flex;
  flex-direction: column;
  height: 560px;
  overflow: hidden;
  overflow-y: auto;
  // gap: 16px;

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($c-gray, .08);
    padding: 8px 0;

    .info {
      display: flex;
      align-items: center;
      gap: 8px;

      .content {
        display: flex;
        flex-direction: column;
        gap: 0px;

        .name {
          display: flex;
          align-items: center;
          gap: 8px;

          span {
            font-size: clamp(14px, 3vw, 14px);
          }
        }

        small {
          font-size: clamp(12px, 3vw, 12px);
          font-weight: 300;
          color: $c-gray;

        }
      }
    }
    .status {
      width: 30%;
      display: flex;
      justify-content: flex-end;
    }
  }
}