.sidebar__area {
  // min-height: 100%;
  background-color: $c-white;
  width: 260px;
  position: sticky;
  top: 16px;
  left: 16px;
  margin-top: 16px;
  bottom: 16px;
  z-index: 101;
  transition: 0.5s;
  height: calc(100vh - 32px);
  border-radius: 8px;
  // position: relative;
  // overflow-x: hidden;
  padding: 24px 24px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  // min-width: fit-content;

  @media (max-width: 1391px) {
    left: -100%;
    position: fixed;
  }

  @media (max-width: 767px) {
    // width: 80%;
  }

  @media (max-width: 600px) {
    // width: 95%;
  }

  &.show {
    top: 16px;
    left: 16px;
    bottom: 16px;
    width: 268px;
    @media (max-width: 1391px) {
      margin-top: 0;
    }
  }

  &--head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;

    img {
      height: 24px;
      object-fit: contain;
    }
  }

  &--body {
    overflow: auto;
  }

  &--menu {
    .ant-menu {
      .ant-menu-item {
        width: 100% !important;
        margin-inline: 0;
      }
      border-inline-end: none !important;

      .ant-menu-submenu-open {
        .ant-menu-submenu-title {
          background-color: #e6f4ff !important;
          .ant-menu-title-content {
            color: $c-primary !important;
          }
        }
      }
      .ant-menu-submenu-title {
        width: 100% !important;
        margin-inline: 0;

        &:hover {
          background-color: #e6f4ff !important;

          .ant-menu-title-content {
            color: $c-primary !important;
          }
        }
      }

      .ant-menu-item {
        padding-left: 16px !important;
        font-weight: 500;
        font-size: 14px;

        &:hover {
          background-color: #e6f4ff !important;

          .ant-menu-title-content {
            color: $c-primary !important;
          }
        }
      }

      .ant-menu-submenu-title {
        padding-left: 16px !important;

        .ant-menu-title-content {
          font-weight: 500;
          font-size: 14px;
        }
      }

      .ant-menu-item-icon,
      .ant-menu-title-content {
        color: $c-text;
      }

      .ant-menu-item-selected {
        & > * {
          color: $c-primary;
        }
      }
    }

    .ant-menu-sub {
      padding: 8px !important;
      border-radius: 8px !important;
      background-color: rgba($c-primary, 0.04) !important;
    }
  }

  &--bottom {
    border-top: 1px solid $c-gray-light;
    padding: 16px 0;
  }
}

.sidebar--overlay {
  @media (max-width: 1391px) {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 27, 36, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 100;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.float__menu {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: $c-white;
  display: none;
  align-items: center;
  gap: 8px;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 9;
  svg {
    color: $c-primary;
  }

  @media (max-width: 1391px) {
    display: flex;
  }
}

.sidebar__area::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.sidebar__area::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.sidebar__area::-webkit-scrollbar-thumb {
  background: rgba($c-primary, 0.02);
  border: 0px none #ffffff;
}

.sidebar__area::-webkit-scrollbar-thumb:active {
  background: rgba($c-primary, 0.02);
}
.sidebar__area::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
}
.sidebar__area::-webkit-scrollbar-track:active {
  background: rgba($c-primary, 0.02);
}
.sidebar__area::-webkit-scrollbar-corner {
  background: transparent;
}
