.solyon {
  background-color: $c-white;
  min-height: 100vh;
  padding: clamp(12px, 10vw, 80px);
  &__container {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 80px;
    max-width: 1028px;
  }
  &__body {
    * {
      font-family: "Nunito";
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 60px;
    position: relative;
  }
  .input--errors {
    color: $c-danger-solyon;
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;
  }
}

.solyon-radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
  * {
    font-family: "Nunito";
  }
  .ant-radio-button-wrapper {
    background: transparent;
    flex: 1;
    height: auto !important;
    padding: 8px;
    text-align: center;
    border-radius: 24px;
    max-width: 316px;
    min-width: 150px;
    padding: 40px 24px;
    border: 1px solid $c-border-solyon;
    &:hover {
      color: $c-primary-solyon;
    }
  }
  .ant-radio-button-wrapper:has(:checked) {
    opacity: 1;
    border: 1px solid $c-primary-solyon;
  }
  .ant-radio-button-wrapper-checked {
    color: $c-primary-solyon;
    .solyon-radio {
      &__button {
        border: 6px solid $c-primary-solyon;
      }
      &__label {
        color: $c-secondary-solyon;
      }
    }
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background: $c-default-border;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
    width: 0px;
  }
  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    row-gap: 24px;
    img {
      height: 120px;
      object-fit: contain;
    }
    &--top {
      display: flex;
      flex-direction: column;
      justify-content: start;
      flex: 1;
    }
  }
  &__label {
    font-size: 24px;
    font-weight: 700;
    color: $c-text-sec-solyon;
    padding-bottom: 12px;
    min-height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__subLabel {
    font-size: 16px;
    font-weight: 400;
    color: $c-text-sec-solyon;
    line-height: 1.2;
  }
  &__button {
    width: 20px;
    height: 20px;
    height: 20px;
    border: 1px solid $c-text-sec-solyon;
    border-radius: 50%;
  }
}

.solyon-header {
  text-align: center;
  margin: auto;
  padding: 0 50px;
  &__row {
    &--icon {
      position: absolute;
      left: clamp(30px, 10vw, 80px);
      color: $c-text-sec-solyon;
      width: clamp(16px, 5vw, 48px);
      height: clamp(16px, 5vw, 48px);
      cursor: pointer;
    }
  }
  &__title {
    max-width: 800px;
    font-family: "Aptos";
    color: $c-secondary-solyon;
    font-size: clamp(18px, 5vw, 40px);
    font-weight: 600;
    line-height: 1.1;
    span {
      color: $c-primary-solyon;
    }
  }
  &__subtitle {
    font-family: "Nunito";
    color: $c-text-sec-solyon;
    font-size: clamp(12px, 2.5vw, 16px);
    padding-bottom: 8px;
  }
}

.solyon-button {
  display: flex;
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.left {
    justify-content: start;
  }

  button {
    padding: 8px 40px;
    height: 44px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 4px;
    border-radius: 100px;
    box-shadow: none;
    > div {
      width: 100%;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:disabled {
      background-color: $c-gray-2-solyon !important;
      color: $c-white;
    }
    &:hover:not(:disabled) {
      border-color: none !important;
      color: inherit !important;
    }
  }

  .small {
    height: 38px;
  }

  .primary {
    background-color: $c-primary-solyon;
    color: $c-white;
    &:hover:not(:disabled) {
      color: $c-white !important;
      background-color: darken($c-primary-solyon, 8%) !important;
    }
  }
  .primary-light {
    background-color: rgba($c-primary-solyon, 0.2);
    color: $c-primary-solyon;
    &:hover:not(:disabled) {
      color: $c-primary-solyon !important;
      background-color: rgba($c-primary-solyon, 0.4) !important;
    }
  }

  .gray-light {
    background-color: $c-background-solyon;
    color: $c-gray-1-solyon;
    &:hover:not(:disabled) {
      color: $c-gray-1-solyon !important;
      background-color: darken($c-background-solyon, 8%) !important;
    }
  }

  .default {
    color: $c-text;
    background-color: $c-white;
    &:hover:not(:disabled) {
      color: $c-primary-solyon !important;
      border: none !important;
    }
  }

  .float-right {
    position: absolute;
    right: 16px;
  }
  .float-left {
    position: absolute;
    left: 16px;
  }
}

.solyon-footer {
  * {
    font-family: "Aptos";
  }
  cursor: pointer;
  font-size: 12px;
  color: $c-gray-1-solyon;

  &__content {
    font-weight: 400;
    line-height: 1.2;
    strong {
      line-height: 2;
      display: block;
    }
    span {
      font-style: italic;
      display: block;
      padding-bottom: 2px;
    }
    p {
      padding: 2px 0;
    }
    &--expanded {
      display: block;
    }
    &--collapsed {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      position: relative;
      max-height: 110px;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
      }
    }
  }
  &__more {
    text-align: center;
    font-weight: 700;
    padding-top: 24px;
  }
}

.solyon-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  div {
    height: 160px;
    width: 160px;
    padding: 16px;
  }
}

.solyon-date-picker {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  * {
    font-family: "Nunito";
  }

  &.row {
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-picker {
    height: 40px;
    width: 100%;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid $c-border-solyon;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }
  .ant-picker-focused {
    box-shadow: none;
    border: 1px solid $c-primary-solyon;
    .ant-picker-suffix {
      color: $c-primary-solyon;
    }
  }

  &__error {
    .solyon-label {
      color: $c-danger-solyon;
    }
    .ant-picker {
      color: $c-danger-solyon;
      border: 1px solid $c-danger-solyon;
    }
    input {
      background: transparent;
      &::placeholder {
        color: $c-danger-solyon;
      }
    }
    .ant-picker-suffix {
      color: $c-danger-solyon !important;
    }
  }
}

.solyon-input {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  * {
    font-family: "Nunito";
  }

  &.row {
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  &--field {
    width: 100%;
  }

  & input {
    height: 40px;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid $c-border-solyon;
    color: $c-text;
    font-weight: 400;
    font-size: clamp(12px, 3vw, 14px);

    &:hover,
    &:focus {
      box-shadow: none;
      border: 1px solid $c-primary-solyon;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    height: 40px;
    input {
      height: auto;
      border: none;
    }
    &:hover,
    &:focus {
      border: 1px solid $c-primary-solyon;
    }
  }
  .ant-input-affix-wrapper-focused {
    border-color: $c-primary-solyon;
    :hover {
      box-shadow: 0;
    }
  }

  & textarea {
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: $c-background;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & input:-webkit-autofill {
    background-color: $c-white !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  & input:-moz-autofill {
    background-color: $c-white !important;
  }

  &__error {
    .solyon-label {
      color: $c-danger-solyon;
    }

    input {
      background: transparent;
      color: $c-danger-solyon;
      border: 1px solid $c-danger-solyon;
      &:hover,
      &:focus {
        border: 1px solid $c-danger-solyon;
      }
    }
  }
}

.solyon-select {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  width: 100%;
  * {
    font-family: "Nunito";
  }
  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  .solyon-label {
    &__icon {
      display: flex;
      align-items: center;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
    border-radius: 8px;
    border: 1px solid $c-border-solyon;
    background-color: $c-white;
    height: 40px;
  }
  .ant-select-focused:where(
      .css-dev-only-do-not-override-byeoj0
    ).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 1px solid $c-primary-solyon !important;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) {
    border: none;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    border-color: $c-primary-solyon;
  }
  & .ant-select-selector {
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    & .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & .ant-select-selection-overflow-item {
    & .ant-select-selection-item {
      background: $c-white !important;
      border: 1px solid $c-primary-solyon !important;
      &:hover {
        border: 1px solid $c-primary-solyon !important;
      }
    }
  }

  &__error {
    .solyon-label {
      color: $c-danger-solyon;
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      ):hover
      .ant-select-selector {
      border-color: $c-danger-solyon;
    }
    .ant-select {
      border: 1px solid $c-danger-solyon;
    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border: 1px solid $c-danger-solyon;
    }
    .ant-select-selector {
      background: transparent;
      color: $c-danger-solyon;
      border: 1px solid $c-danger-solyon;
      &:hover,
      &:focus {
        border: 1px solid $c-danger-solyon;
      }
    }
  }
}

.solyon-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $c-primary-solyon;
  font-size: 16px;
  font-weight: 600;
  * {
    font-family: "Nunito";
  }

  &__optional {
    font-style: italic;
    color: $c-gray-light4;
  }

  &__sub {
    font-size: 12px;
    font-weight: 400;
    padding-left: 4px;
  }

  &__icon {
    display: flex;
  }

  &__required {
    color: $c-danger-solyon;
  }
}

.hide--icon:empty,
.hide--label:empty,
.hide--label {
  display: none !important;
}

.solyon-card {
  width: 100%;
  &__container {
    border-radius: 8px;
    padding: 16px;
    background-color: $c-background-solyon;
  }
}

.solyon-checkbox {
  display: flex;
  gap: 8px;
  * {
    font-family: "Nunito";
  }
  &__label {
    line-height: 24px;
    &--text {
      color: $c-text-sec-solyon;
      text-align: justify;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    &--subLabel {
      padding-top: 12px;
      color: $c-text-sec-solyon;
      text-align: justify;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }
  &:has(input[type="checkbox"]:checked) {
    .solyon-checkbox__label--text {
      color: $c-secondary-solyon;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
    min-width: 24px;
    min-height: 24px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    border: 1px solid $c-border-solyon;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    position: relative;
  }

  input[type="checkbox"]::before {
    content: "";
    color: $c-primary-solyon;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 50%;
    height: 50%;
    transition: opacity 0.3s;
    background-image: url("../../assets/icons/check_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  input[type="checkbox"]:checked {
    &:before {
      opacity: 1;
    }
    border: 1px solid $c-primary-solyon;
    background-color: $c-primary-solyon;
  }
}

.solyon-scroll-container {
  padding: 12px;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.solyon-needs {
  display: flex;
  justify-content: space-around;
  width: 100%;

  * {
    font-family: "Nunito";
  }

  .selection-grid {
    display: flex;
    gap: 40px;
  }

  .selection-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    width: min-content;
  }

  .selection-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .selection-icon {
    height: 90px;
    object-fit: contain;
  }

  .selection-title {
    color: $c-secondary-solyon;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  .selection-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .selection-radio {
    display: flex;
    align-items: center;
    background: $c-background-solyon;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $c-gray-1-solyon;
    white-space: nowrap;
    min-width: 170px;
    cursor: pointer;
    &:hover {
      background: rgba($c-primary-solyon, 0.05);
    }

    &.selected {
      background-color: rgba($c-primary-solyon, 0.1);
      color: $c-primary-solyon;
      font-weight: 700;
    }
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $c-primary-solyon;
    background-color: $c-primary-solyon;
  }
  .ant-radio-wrapper .ant-radio-checked::after {
    border-color: $c-primary-solyon;
  }
  .ant-radio-wrapper {
    margin: 0;
    &:hover .ant-radio-inner {
      border-color: $c-primary-solyon;
    }
  }
  .ant-radio-wrapper span.ant-radio + * {
    padding-inline-start: 16px;
    padding-inline-end: 4px;
  }
}

.solyon {
  .related_persons {
    max-width: 600px;
    margin: auto;
  }
  .beneficiary_container {
    display: flex;
    border: 1px solid $c-primary-solyon;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    column-gap: 8px;
    margin: auto;
    &:has(.hidden) {
      border-radius: 100px;
      padding: 8px 8px 8px 24px;
    }
    &--main,
    &--expand--row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      // .solyon-checkbox {
      //   height: 40px;
      // }
      &--left {
        display: flex;
        gap: 8px;
      }
    }
    &--main {
      gap: 16px;
      @media (width <= 767px) {
        justify-content: center;
        // text-align: center;
        gap: 8px;
      }
      img {
        height: 24px;
        object-fit: contain;
      }
      .question {
        line-height: 24px;
        color: $c-primary-solyon;
        font-size: clamp(14px, 3vw, 16px);
      }
    }
    &--expand {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      .divider {
        margin-block: 0;
      }
      &--row {
        gap: 8px;
        .datepicker__wrapper {
          flex: 0.8;
          min-width: 100px;
        }
        .children-count {
          background-color: $c-background-solyon;
          border: 1px solid $c-border-solyon;
          border-radius: 100px;
          padding: 8px;
          display: flex;
          gap: 8px;
          .ant-input,
          .ant-input-disabled {
            text-align: center;
            width: 70px;
            background-color: transparent !important;
            border: none;
          }

          &__button {
            button {
              padding: 16px !important;
            }
          }
        }
      }
      .children-birthday {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &--row {
          display: flex;
          gap: 8px;
          justify-content: space-between;
          align-items: flex-end;
          > .solyon-label {
            height: 40px;
          }
        }
      }
    }
  }
  .beneficiary_chips {
    justify-self: center;
    border-radius: 24px;
    padding: 16px;
    gap: 6px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &--button {
      border-radius: 100px;
      padding: 4px 8px;
      font-weight: 500;
      background: rgba($c-primary-solyon, 0.2);
      border: 1px solid $c-primary-solyon;
      display: flex;
      align-items: center;
      gap: 4px;
      text-transform: capitalize;
      color: $c-primary-solyon;
      font-size: 14px;
      > button {
        cursor: pointer;
        border: none;
        display: flex;
        color: $c-primary-solyon;
        &:not(:disabled):hover {
          color: $c-primary-solyon;
        }
      }
    }
  }
  &--products {
    width: 100%;

    @media (width <= 767px) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  .product__item {
    height: 100%;
    display: flex;
    align-items: center;

    & > div {
      flex-grow: 1;
    }

    &--wrapper {
      background-color: $c-white;
      border: 1px solid $c-border-solyon;
      height: 100%;
      border-radius: 12px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      // text-align: center;
      row-gap: 16px;
      min-height: 300px;
      @media (width <= 767px) {
        justify-content: center;
      }
    }
    &--header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      &--top {
        display: flex;
        justify-content: space-between;
        gap: 4px;
        width: 100%;
      }
    }

    &--title {
      width: 100%;
      font-weight: 600;
      font-size: clamp(14px, 3vw, 18px);
      color: $c-secondary-solyon;
    }
    &--icon {
      height: 24px;
      object-fit: contain;
    }

    &--description {
      color: $c-default-text-gray-light;
      font-size: 12px;
      font-weight: 500;
    }

    &--actions {
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      justify-content: center;
      display: flex;
      font-size: 12px;
      gap: 4px;
      .row {
        cursor: pointer;
        display: flex;
        gap: 4px;
      }
    }
    &--info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &--title {
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 700;
        color: $c-secondary-solyon;
      }
      &--list {
        display: flex;
        flex-direction: column;
        list-style: none;

        .item {
          display: flex;
          gap: 4px;
          font-size: clamp(10px, 3vw, 12px);
          color: $c-secondary-solyon;
          font-weight: 400;
          svg {
            color: $c-success;
            padding: 4px;
            height: 20px;
            width: 20px;
          }
          span {
            line-height: 20px;
          }
        }
      }
    }
    &--link {
      color: $c-primary-solyon;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
      padding-top: 32px;
    }
  }

  .product__item--recommended {
    order: 2;
    @media (width <= 767px) {
      order: 1;
    }

    &--header {
      padding: 4px 8px;
      color: $c-primary-solyon;
      // text-align: center;
      font-size: 12px;
      line-height: 12px;
      font-weight: 700;
      border: 1px solid $c-primary-solyon;
      border-radius: 4px;
    }

    .product__item {
      &--wrapper {
        border-color: $c-primary-solyon;
      }
      &--link {
        padding-top: 64px;
      }
    }
  }
}

.solyon-swiper {
  width: 100%;
  .swiper-slide {
    width: 100%;
  }
  .swiper-wrapper {
    align-items: flex-end !important;
    width: 100%;
    height: auto;
    position: relative;
  }

  .swipper--pagination {
    .slider__controls {
      position: relative;
      z-index: 12;

      & .slider__pagination {
        text-align: center;
        // margin-top: 16px;
        left: 0;
        right: 0;
        margin: 16px auto 0 auto;
        transform: translateX(0%);

        & .swiper-pagination-bullet {
          width: 13px;
          height: 10px;
          display: inline-block;
          background: $c-main;
          opacity: 0.2;
          margin: 0 5px;
          -webkit-border-radius: 20px;
          border-radius: 20px;
          -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
          -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
          transition: opacity 0.5s, background-color 0.5s, width 0.5s;
          -webkit-transition-delay: 0.5s, 0.5s, 0s;
          -o-transition-delay: 0.5s, 0.5s, 0s;
          transition-delay: 0.5s, 0.5s, 0s;
        }
      }

      & .swiper-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer;
      }

      & .slider__pagination .swiper-pagination-bullet-active {
        opacity: 1;
        background: $c-main;
        width: 30px;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }
}

.solyon-swiper + .swiper__arrows {
  margin-top: 32px;
  position: relative;
  width: max-content;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 50px;
  padding: 0 2px;
  margin: auto;
  margin-top: 32px;

  .swiper-button-prev,
  .swiper-button-next {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: transparent;
    background-color: rgba($c-primary-solyon, 1);
    border: none;
    color: $c-white;
    cursor: pointer;
    z-index: 1;
    position: static;
    margin: auto 0;

    &::after {
      color: $c-white;
      font-size: 18px;
      font-weight: 600;
      @media (width <= 991px) {
        font-size: 16px;
      }
    }

    @media (width <= 991px) {
      height: 40px;
      width: 40px;
    }
  }
  .swiper-button-disabled {
    background-color: $c-gray-2-solyon;
  }
}

.solyon-cost {
  &--main {
    display: flex;
    gap: 4px;
    text-align: left;
    color: $c-secondary-solyon;

    &--integer {
      font-size: 62px;
      font-weight: 700;
      line-height: 1;
    }
    &--decimal {
      font-size: 24px;
      display: flex;
      gap: 4px;
      line-height: 31px;
    }
    &--period {
      color: $c-primary-solyon;
      font-size: 21px;
      line-height: 1;
    }
  }
  &--secondary {
    color: $c-gray-1-solyon;
    font-size: 12px;
    line-height: normal;
  }
}

.solyon-upsells {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &--link {
      cursor: pointer;
      color: $c-text-sec-solyon;
      font-family: "Nunito";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      text-decoration-thickness: 4%;
      text-underline-offset: 12%;
      text-underline-position: from-font;
      text-align: center;
    }
    &--link--hidden {
      visibility: hidden;
    }
  }
  &-card {
    padding: 40px 24px;
    width: 232px;
    border-radius: 24px;
    border: 1px solid $c-border-solyon;
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      img {
        height: 18px;
        object-fit: contain;
        max-width: 100%;
      }
      button {
        height: 26px;
        padding: 6px;
      }
    }
    &__not-selected {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }
}

.solyon-upsells-recap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__card {
    border-radius: 100px;
    background-color: $c-background-solyon;
  }
  &__container {
    display: flex;
    padding: 8px 16px 8px 34px;
    align-items: center;
    gap: 24px;
  }

  &__text {
    max-width: 124px;
    color: $c-secondary-solyon;
    font-family: "Nunito";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  &--gray {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

.solyon-upsells__details {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &--header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  &--title {
    color: $c-primary-solyon;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &--icon {
    width: max-content;
    object-fit: contain;
  }

  .guarantees-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
    th {
      color: $c-primary-solyon;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    th,
    td {
      padding: 8px;
      text-align: left;
    }
    td {
      color: $c-secondary-solyon;
    }
  }
  tr:not(:has(th)):nth-child(2n + 1) {
    background-color: $c-background-solyon;
  }
}

.solyon-info {
  padding: 16px 24px;
  border-radius: 24px;
  background: rgba($c-primary-solyon, 0.05);
  max-width: 724px;
  color: $c-primary-solyon;
  text-align: center;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    font-weight: 700;
  }
}
