.ant-notification-notice {
  padding: 16px 16px !important;
}
.ant-notification-notice-error {
  background-color: $c-white !important;
  border-radius: 100px;
  border: 2px solid $c-danger !important;

  .ant-notification-notice-icon {
    color: $c-danger !important;
  }
  .ant-notification-notice-message {
    color: $c-danger !important;
    font-weight: 700;
    margin-bottom: 0 !important;
  }
  .ant-notification-notice-description {
    font-weight: 500;
  }
}

.ant-notification-notice-success {
  background-color: $c-white !important;
  border-radius: 100px;
  border: 2px solid $c-success !important;

  .ant-notification-notice-icon {
    color: $c-success !important;
  }
  .ant-notification-notice-message {
    color: $c-success !important;
    font-weight: 700;
    margin-bottom: 0 !important;
  }
  .ant-notification-notice-description {
    font-weight: 500;
  }
}

.ant-notification-notice-info {
  background-color: $c-white !important;
  border-radius: 100px;
  border: 2px solid $c-primary !important;

  .ant-notification-notice-icon {
    color: $c-primary !important;
  }
  .ant-notification-notice-message {
    color: $c-primary !important;
    font-weight: 700;
    margin-bottom: 0 !important;
  }
  .ant-notification-notice-description {
    font-weight: 500;
  }
}
