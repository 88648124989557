.RADIANCE_COSA .documents__section {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  border: 1px solid var(--Grey-5, #e0e0e0);
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  gap: 16px;
  &--title {
    color: var(--Primary, #bd0027);
    font-size: clamp(18px, 3vw, 20px);
    font-weight: 600;
  }
  &--content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__card {
      border-radius: 12px;
      padding: 16px;
      background: #fff8fa;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_title {
        font-weight: 600;
        gap: 8px;
        display: flex;
        align-items: center;
      }
      .button__wrapper .danger {
        background: #bd0027 !important;
      }
    }
  }
  &--pagination {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
    color: #bdbdbd;
    font-weight: 500;
    button {
      height: 30px !important;
      padding: 10px !important;
      color: #bdbdbd !important;
      svg {
        color: #808080 !important;
      }
      &:disabled svg {
        color: #bdbdbd !important;
      }
    }
  }
}

.RADIANCE,
.RADIANCE_COSA {
  background-color: $c-white;
  position: relative;
  min-height: 100vh;

  * {
    font-family: "Poppins";
  }

  .b2c__area {
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    gap: 40px;
    padding: 24px 0 16%;

    @media (width <= 767px) {
      padding: 0 0 38%;
    }
  }
  .b2c__area {
    &.bg-transparent {
      background: transparent !important;
    }
    .input__wrapper,
    .datepicker__wrapper,
    .select__wrapper {
      .label__area {
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 500;
        color: $c-radiance-template;
      }

      .ant-input,
      .ant-picker {
        background-color: $c-white;
        border-radius: 100px;
        border: 1px solid $c-radiance-border !important;
        padding: 0 16px;
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 400;
      }

      .ant-select-selector {
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 400;
      }

      .ant-select {
        background-color: $c-white;
        border-radius: 100px;
        border: 1px solid $c-radiance-border !important;
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 400;
      }

      .ant-select-selector {
        background: transparent !important;
        box-shadow: none;
        border-radius: 100px;
        &:focus {
          border: 100px;
        }
      }
    }

    .__error {
      .ant-input,
      .ant-picker,
      .ant-select-selector {
        border-radius: 100px;
        border: 1px solid $c-danger !important;
        background-color: $c-white !important;
      }
    }
    .checkbox__wrapper {
      input[type="checkbox"] {
        border-radius: 50%;
        height: 26px;
        width: 26px;
        min-height: 26px;
        min-width: 26px;
        border: 1px solid $c-radiance-text-gray-light;
        &:checked {
          border: none;
          background-color: $c-radiance-template;
          &::before {
            background-image: url("../../assets/icons/check_white.svg");
          }
        }
      }
      .label__area {
        line-height: clamp(18px, 3vw, 24px);
      }
      &:has(:disabled) {
        cursor: not-allowed !important;
        opacity: 0.5;
      }
    }
    .button__wrapper.danger--link {
      button {
        color: #be0a2a !important;
        font-weight: 600;
      }
    }
    .button__wrapper {
      .danger--link {
        color: #be0a2a !important;
        font-weight: 500;
      }
      button {
        border-radius: 100px;
      }
      .primary {
        background-color: $c-radiance-template;
        color: $c-white;
        border: 2px solid transparent;

        &:not(:disabled):hover {
          color: $c-radiance-template !important;
          background-color: $c-radiance-button-hover !important;
          border: 2px solid $c-radiance-template !important;

          @media (width <= 768px) {
            background-color: $c-radiance-template !important;
            border: 2px solid $c-radiance-template !important;
            color: $c-white !important;
          }
        }
        &[disabled],
        &[disabled]:active,
        &[disabled]:focus,
        &[disabled]:hover {
          background-color: $c-radiance-template !important;
          color: $c-white;
          opacity: 0.3;
        }
      }
      .primary--outlined {
        color: $c-radiance-template;
        border: 2px solid $c-radiance-template;

        &:not(:disabled):hover {
          background-color: $c-radiance-button-hover;
          border: 2px solid $c-radiance-template !important;

          @media (width <= 768px) {
            background-color: $c-radiance-template !important;
            border: 2px solid $c-radiance-template !important;
            color: $c-white !important;
          }
        }
        &[disabled],
        &[disabled]:active,
        &[disabled]:focus,
        &[disabled]:hover {
          background-color: $c-white;
          border: 2px solid $c-radiance-template;
          opacity: 0.3;
        }
      }

      svg &[disabled],
      svg &[disabled]:active,
      svg &[disabled]:focus,
      svg &[disabled]:hover {
        opacity: 0.3;
      }
      &:has(> button:disabled) svg {
        color: $c-radiance-disabled-button;
      }
      &.b2c__area--actions__next:has(> button:not(:disabled)) svg {
        color: $c-radiance-template;
      }
    }

    &--header {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      @media (width <= 767px) {
        margin: 0;
      }

      &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        @media (width <= 767px) {
          margin-bottom: 16px;
        }
        img {
          height: 100px;
          width: 100px;
          @media (width <= 767px) {
            height: 80px;
            width: 80px;
          }
        }
      }

      &__title {
        text-align: center;
        font-size: clamp(22px, 3vw, 32px);
        line-height: clamp(30px, 3vw, 40px);
        font-weight: 700;
        span {
          color: $c-radiance-template;
        }
      }
      &__subtitle {
        color: $c-default-text-gray-light;
        text-align: center;
        font-size: clamp(14px, 3vw, 16px);
        line-height: clamp(20px, 3vw, 24px);
        font-weight: 500;
      }

      &__desc {
        color: $c-black;
        text-align: center;
        font-size: clamp(16px, 3vw, 18px);
        line-height: clamp(20px, 3vw, 26px);
        font-weight: 500;
      }
    }
    &--products {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
      // align-items: center;
      // gap: 16px;

      @media (width <= 767px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
    }
    .adherent-info {
      .b2c-radio {
        .ant-radio-button-wrapper {
          min-height: 90px !important;
          border: 1px solid $c-radiance-border;
        }
        .label__area {
          font-size: 18px;
          font-weight: 600;
          justify-content: center;
          margin-bottom: 12px;
        }
      }
    }

    .adherent-telecoms {
      .already-adherent {
        padding: 24px;
        border: 1px solid $c-radiance-border;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          gap: 16px;

          @media (width <= 767px) {
            flex-direction: column;
            justify-content: center;
          }
        }
        > div.territorial-agent {
          border-top: 1px solid $c-radiance-border;
          padding-top: 16px;
        }
      }
    }

    .beneficiary_container {
      border: 1px solid $c-radiance-template;
      border-radius: 24px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 543px;
      margin: auto;

      @media (width <= 767px) {
        padding: 24px 16px;
      }

      &--main,
      &--expand--row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
      &--main {
        gap: 16px;
        @media (width <= 767px) {
          justify-content: center;
          text-align: center;
          gap: 8px;
        }
        .question {
          color: $c-radiance-template;
          font-size: clamp(14px, 3vw, 16px);
        }
      }
      &--expand {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .divider {
          margin-block: 0;
        }
        &--row {
          gap: 8px;
          .datepicker__wrapper {
            flex: 0.8;
            min-width: 100px;
          }
          .children-count {
            display: flex;
            gap: 8px;
            .ant-input {
              text-align: center;
              width: 70px;
            }
          }
        }
        .children-birthday {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
    .beneficiary_chips {
      justify-self: center;
      border-radius: 24px;
      padding: 16px;
      gap: 6px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &--button {
        border-radius: 100px;
        padding: 4px 16px;
        font-weight: 500;
        background: #ececec;
        border: 1px solid #d2d0d2;
        display: flex;
        align-items: center;
        gap: 4px;
        text-transform: capitalize;
        color: #606060;
        font-size: 14px;
        > button {
          cursor: pointer;
          border: none;
          display: flex;
          color: #606060;
          &:not(:disabled):hover {
            color: $c-radiance-template;
          }
        }
      }
    }

    &--content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      grid-gap: 32px;
      &__related_persons {
        max-width: 600px;
        margin: auto;
      }
      &.columns {
        display: flex;
        flex-direction: column;
      }
      .divider {
        height: 1px;
        background: $c-radiance-border;
        padding-inline: 100px;
      }
      label.status {
        width: 100%;
        height: auto;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0);
        border: 2px solid $c-radiance-template;
        border-radius: 4px;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
        display: flex;
      }

      &__children-count {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        gap: 42px;
        .ant-input {
          text-align: center;
        }
        .option_container {
          width: 100%;
          grid-column-gap: 4px;
          grid-row-gap: 4px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
      .spouse-birthday {
        margin-top: 42px;
        margin-bottom: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-picker {
          width: 300px;
        }
      }
    }
    .button-small-rounded {
      button {
        padding: 0 !important;
        color: $c-white;
        background-color: $c-radiance-template;
        border-radius: 100px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $c-white;
        }

        &:not(:disabled):hover {
          color: $c-white;
          background-color: $c-radiance-template;
        }
        &:disabled {
          background-color: $c-radiance-border;
        }
      }
    }
    &--actions {
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      padding: 16px 8px;
      background-color: $c-white;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      z-index: 9;

      @media (max-width: 768px) and (max-height: 768px) {
        position: fixed;
        bottom: 0px;
        box-shadow: rgba(200, 205, 210, 0.2) 0px -2px 20px;
      }

      @media (max-width: 320px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__next {
        display: flex;
        align-items: center;
        button {
          color: $c-white;
          background-color: $c-radiance-template;
          border-radius: 100px !important;
          width: 100%;
          padding-inline: 32px;
          border: 2px solid transparent;

          @media (max-width: 560px) {
            padding: 0 18px;
          }

          &:disabled {
            color: $c-white !important;
            background-color: $c-radiance-disabled-button;
            border: 2px solid $c-radiance-disabled-button !important;
          }

          &:not(:disabled):hover {
            color: $c-radiance-template !important;
            background-color: $c-radiance-button-hover !important;
            border: 2px solid $c-radiance-template !important;

            // &::before {
            //   border-left-color: lighten($c-white, 10%);
            //   box-shadow: -8px 0 0 -2px lighten($c-radiance-template, 10%),
            //     -12px 0 0 0 lighten($c-white, 10%),
            //     -24px 0 0 -6px lighten($c-radiance-template, 10%);
            // }

            // &::after {
            //   border-right-color: lighten($c-white, 10%);
            //   box-shadow: 8px 0 0 -2px lighten($c-radiance-template, 10%),
            //     12px 0 0 0 lighten($c-white, 10%),
            //     24px 0 0 -6px lighten($c-radiance-template, 10%);
            // }
          }
        }

        svg {
          width: 32px;
        }
        @media (max-width: 560px) {
        }

        // &:not(:disabled)::before,
        // &:not(:disabled)::after {
        //   content: "";
        //   position: absolute;
        //   width: 30px;
        //   height: 40px;
        //   background: transparent;
        //   border-radius: 50%;
        // }

        // &::before {
        //   border-left: 4px solid $c-white;
        //   left: -8px;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   box-shadow: -8px 0 0 -2px $c-radiance-template, -12px 0 0 0 $c-white,
        //     -24px 0 0 -6px $c-radiance-template;
        // }

        // &::after {
        //   border-right: 4px solid $c-white;
        //   right: -8px;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   box-shadow: 8px 0 0 -2px $c-radiance-template, 12px 0 0 0 $c-white,
        //     24px 0 0 -6px $c-radiance-template;
        // }
      }

      &__subscribe {
        color: $c-white;
        background-color: $c-default-success;

        &:not(:disabled):hover {
          color: $c-white !important;
          background: $c-radiance-button-hover !important;
        }
      }

      .prev {
        button {
          color: $c-radiance-text-gray-light;
          font-weight: 500;
          cursor: pointer;
          box-shadow: none;
        }

        span {
          cursor: pointer;
        }

        &:not(:disabled):hover {
          color: $c-radiance-template;
        }

        @media (width <= 768px) {
          span {
            display: none;
          }
        }
      }
    }

    &--infos {
      width: 100%;
      max-width: 600px;
      justify-content: space-between;
      margin-top: 40px;
      display: flex;
      color: $c-radiance-template;
      > div {
        width: 100%;
        text-align: justify;
        border: 3px solid $c-radiance-template;
        border-radius: 4px;
        padding: 18px;
        line-height: 16px;
      }
    }
  }

  .checkbox__wrapper {
    @media (max-width: 768px) {
      align-items: flex-start !important;
    }
  }

  .need_container {
    grid-area: span 1 / span 2 / span 1 / span 2;
    &--header {
      width: 100%;
      max-width: 600px;
      align-items: flex-end;
      display: flex;
      gap: 8px;
      h3 {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        font-size: 12px;
      }
    }
    &--content {
      width: 100%;
      height: 48px;
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      background-color: $c-default-border;
      border-radius: 4px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 0;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;
      display: flex;
      label {
        width: 100%;
        height: 40px;
        text-align: center;
        cursor: pointer;
        border: 1px solid $c-white;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 0;
        font-size: 16px;
        display: flex;
        span {
          font-weight: 700;
        }
        &.selected {
          background-color: $c-radiance-template;
          color: $c-white;
          border-style: none;
          border-width: 1px;
          border-radius: 5px;
        }
      }
    }
  }

  .product__item {
    height: 100%;
    display: flex;
    align-items: center;

    & > div {
      flex-grow: 1;
    }

    &--wrapper {
      background-color: $c-white;
      border: 1px solid $c-radiance-border;
      height: 100%;
      border-radius: 12px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      padding: 24px 16px;
      text-align: center;
      row-gap: 16px;
      min-height: 300px;
      @media (width <= 767px) {
        justify-content: center;
      }
    }
    &--header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;
    }

    &--title {
      width: 100%;
      font-weight: 600;
      font-size: clamp(14px, 3vw, 18px);
    }
    &--price {
      display: flex;
      align-items: baseline;
      gap: 4px;
      color: $c-radiance-template;
      h1 {
        font-size: clamp(32px, 3vw, 40px);
        font-weight: 700;
      }
      span {
        font-size: 12px;
      }
    }
    &--description {
      color: $c-default-text-gray-light;
      font-size: 12px;
      font-weight: 500;
    }

    &--actions {
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      justify-content: center;
      display: flex;
      font-size: 12px;
      gap: 4px;
      .row {
        cursor: pointer;
        display: flex;
        gap: 4px;
      }
    }
    &--info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (width <= 767px) {
        // display: none;
      }

      &--title {
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 600;
        color: $c-radiance-text-black;
      }
      .--list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style: none;

        .item {
          font-size: clamp(10px, 3vw, 12px);
          padding: 4px 8px;
          background-color: $c-white;
          color: $c-radiance-text-gray;
          border-radius: 4px;
          font-weight: 400;
          span {
            font-weight: 600;
          }
        }
      }
    }
    &--link {
      color: $c-radiance-text-gray;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
      padding-top: 32px;
    }
  }

  .product__item--recommended {
    background-color: $c-radiance-template;
    padding: 6px;
    border-radius: 16px;
    order: 2;

    @media (with <= 767px) {
      order: 1;
    }

    &--header {
      padding: 14px 24px;
      color: $c-white;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }

    .product__item {
      &--wrapper {
        background-color: #fff8fa !important;
        border: none !important;
      }
    }
  }

  .b2c-radio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    .grid--4 {
      grid-gap: 8px;
    }
    .label__area {
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 700;
    }
    .ant-radio-group {
      justify-content: center;
    }
    .custom--label {
      font-size: 18px;
      display: flex;
      align-items: center;
      flex-direction: column;
      column-gap: 8px;
      &:has(.row) {
        flex-direction: row;
        justify-content: space-between;
      }
      .sub-label {
        font-size: 14px;
        color: $c-radiance-text-gray;
      }
    }
    .ant-radio-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      flex: 1;
      height: auto !important;
      padding: 16px 32px;
      text-align: center;
      border-radius: 24px;
      border: 1px solid $c-radiance-border;
      &:not(:has(img)) {
        border-radius: 100px;
      }
      &:before {
        border: 0;
      }

      &:not(:disabled):hover {
        color: $c-radiance-template;
      }
    }
    .ant-radio-button-wrapper-disabled {
      &:not(.ant-radio-button-wrapper-checked):hover {
        color: $c-radiance-text-gray-light !important;
      }
    }
    .ant-radio-button-wrapper:has(:checked) {
      opacity: 1;
      border: 1px solid $c-radiance-template !important;
    }
    .ant-radio-button-wrapper-checked {
      color: $c-radiance-template;
      .custom--label {
        .sub-label {
          color: $c-radiance-template !important;
        }
      }
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }

    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: $c-radiance-template;
    }
    &:has(.label-with-image) {
      .ant-radio-button-wrapper {
        padding: 18px 24px;
        background: transparent;
        opacity: 0.5;
        &:not(:disabled):hover {
          color: $c-red;
        }
      }
      .ant-radio-button-wrapper:not(:has(:checked)) {
        border: none;
      }
      .ant-radio-button-wrapper:has(:checked) {
        opacity: 1;
        border: 1px solid $c-radiance-template;
      }

      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        )::before {
        background-color: transparent;
      }
      label {
        img {
          height: 124px;
        }
        input[type="radio"] {
          box-sizing: border-box;
          padding: 0;
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }

  .b2c-stepper {
    width: 100%;
    max-width: 600px;
    margin-bottom: 32px;
    &__linker {
      flex: 1;
      height: 1px;
      background-color: $c-radiance-template;
      margin-top: 38px;
    }
    > ul {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      > li {
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        color: rgb(218, 218, 218);
        &.active {
          > span:first-child {
            background: $c-radiance-template;
          }
        }
        > span:first-child {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-color: rgb(218, 218, 218);
          border-width: 1px;
          border-style: solid;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
  }

  .stepper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    background-color: $c-white;
    border-radius: 12px;
    z-index: 9;

    @media (width <= 767px) {
      // top: 0;
      // position: fixed;
      // box-shadow: rgba(209, 212, 216, 0.2) 0px 0px 24px;
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      position: relative;

      .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $c-radiance-border;
        color: white;
        font-weight: 600;
        margin-bottom: 4px;
        z-index: 20;
      }

      &:not(:first-child)::before {
        content: "";
        width: 100%;
        height: 1px;
        background: $c-radiance-border;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, 15px);
      }

      &.completed::before {
        background: $c-radiance-template;
      }

      &.completed .step-number {
        background: $c-radiance-template;
      }

      .step-title {
        color: $c-radiance-border;
        font-size: clamp(12px, 3vw, 14px);
      }

      &.completed .step-title {
        color: $c-radiance-template;
      }
    }
  }

  .additional-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    &__header {
      color: $c-radiance-text-gray;
      text-align: center;
      font-size: 14px;
    }
    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      @media (max-width: 560px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    &__item {
      display: flex;
      justify-self: center;
      align-items: center;
      gap: 8px;
      &--check {
        width: 24px;
        height: 24px;
        min-width: 24px;
        background-color: #b4c83280;
        border-radius: 50%;
        position: relative;
        &--icon {
          position: absolute;
          bottom: 4px;
          left: 4px;
          color: #be0a2a;
          font-size: 18px;
        }
      }
      &--label {
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 600;
      }
    }
  }

  .get-in-touch_container {
    width: 100%;
    display: flex;
    gap: 16px;
    @media (width <= 767px) {
      flex-direction: column;
    }
  }

  .contact__me {
    width: 100%;
    border: 1px solid $c-radiance-border;
    border-radius: 12px;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    @media (width <= 767px) {
      flex-direction: column;
      justify-content: center;
      padding: 24px 16px;
      .button__wrapper {
        justify-content: center;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        font-size: clamp(18px, 3vw, 24px);
        font-weight: 600;
      }
      p {
        font-size: clamp(12px, 3vw, 16px);
        font-weight: 400;
      }

      @media (width <= 767px) {
        text-align: center;
      }
    }
    button {
      padding: 24px 38px;
    }
  }

  .contact-us {
    @media (width <= 768px) {
      padding-top: 32px;
    }
    &__header {
      h1 {
        font-size: 24px;
        font-weight: 700;
      }
      h3 {
        font-size: 18px;
        font-weight: 500;
      }
    }
    &__content {
      text-align: center;
      h3 {
        font-size: clamp(18px, 3vw, 20px);
        font-weight: 600;
      }
    }
    &__action {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      align-items: center;
      span {
        font-size: clamp(16px, 3vw, 16px);
        text-align: center;
      }
      a {
        text-decoration: none;
        button {
          height: 60px;
          padding: 16px 32px;
        }
      }
    }
    &__link {
      padding-block: 24px;
      text-decoration: underline;
      cursor: pointer;
      color: $c-radiance-template;
    }
  }
}

.ant-upload-list-item-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap !important;
}

// .swiper__arrows {
//   position: absolute;
//   top: 50%;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   pointer-events: none; /* Allow click-through */

//   .swiper-button-prev,
//   .swiper-button-next {
//     position: absolute;
//     z-index: 10;
//     top: 50%;
//     width: 44px;
//     height: 44px;
//     margin-top: -22px; /* Centers the buttons vertically */
//     cursor: pointer;
//     pointer-events: auto; /* Ensure these buttons are clickable */
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
//     color: white;
//     border-radius: 50%;
//     transition: background-color 0.3s;
//   }

//   .swiper-button-prev:hover,
//   .swiper-button-next:hover {
//     background-color: rgba(0, 0, 0, 0.7); /* Darker on hover */
//   }

//   .swiper-button-prev {
//     left: 10px;
//   }

//   .swiper-button-next {
//     right: 10px;
//   }
// }

.subscription__section {
  width: 100%;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  &--icon {
    display: flex;
    justify-content: center;
  }
  &--heading {
    color: var(--Gray-1, #333);
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }
  .product__reacp {
    display: flex;
    padding: 24px;
    flex-direction: column;
    // align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Grey-5, #e0e0e0);
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);

    &--name {
      color: var(--Primary, #bd0027);
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 600;
      @media (width <= 768px) {
        text-align: center;
      }
    }

    &--top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (width <= 768px) {
        flex-direction: column;
        justify-content: center;
        gap: 16px;
      }

      button {
        height: auto;
        text-decoration: underline;
        padding: 0;
      }

      .--date {
        display: flex;
        gap: 4px;
        p {
          font-weight: 600;
        }
      }

      &--offer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        @media (width <= 768px) {
          align-items: center;
          flex-direction: column;
        }

        .content {
          display: flex;
          align-items: center;
          gap: 8px;
          @media (width <= 768px) {
            align-items: center;
            flex-direction: column;
          }
        }

        .space {
          justify-content: space-between;
          @media (width <= 768px) {
            align-items: center;
            flex-direction: column;
          }
        }

        .label {
          color: #000;
          text-align: center;
          font-size: clamp(18px, 3vw, 24px);
          font-weight: 500;
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .price__tag {
          color: var(--Primary, #bd0027);
          sub {
            font-size: clamp(16px, 3vw, 24px);
          }
          > span {
            font-weight: 700;
            font-size: clamp(32px, 3vw, 40px);
            color: var(--Primary, #bd0027);
          }
        }
      }

      &--infos {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        .item {
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: flex-end;

          @media (width <= 768px) {
            justify-content: center;
            flex-wrap: wrap;
          }

          label {
            font-size: clamp(12px, 3vw, 16px);
            font-weight: 300;
          }
          p {
            color: #5b5a5a;
            font-weight: 600;
            font-size: clamp(12px, 3vw, 16px);
          }
        }
      }
    }
    &--bottom {
      width: 100%;
      padding-top: 16px;
      display: flex;
      border-top: 1px solid #e0e0e0;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      @media (width <= 768px) {
        justify-content: center;
        flex-direction: column;
        gap: 16px;
      }
      &__left {
        > span {
          color: var(--Gray-1, #333);
          font-size: 18px;
          font-weight: 600;
        }
        > p {
          color: var(--Gray-1, #333);
          font-size: 14px;
          font-weight: 300;
        }

        @media (width <= 768px) {
          text-align: center;
        }
      }
    }
    &--ria {
      &__title {
        color: var(--Primary, #bd0027);
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 16px;
      }
    }
    &--retired {
      border-radius: 12px;
      background: rgba(189, 0, 39, 0.06);
      padding: 16px;
      display: flex;
      gap: 16px;
      &__title {
        font-size: 14px;
        font-weight: 500;
        display: block;
      }
    }

    .--line {
      border-bottom: 1px solid $c-radiance-gray-light;
      padding-bottom: 16px;
    }
  }
}
.subscription__informations {
  &--title {
    color: #333;
    font-size: clamp(18px, 3vw, 22px);
    font-weight: 700;
    margin: 24px 0 0;
    display: block;
    @media (width <= 767px) {
      text-align: center;
    }
  }
  &--children {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--Primary, #bd0027);
    &_title {
      color: var(--Gray-1, #333);
      font-size: 16px;
      font-weight: 600;
    }
    &__card {
      padding: 8px;
      padding-bottom: 24px;
      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}
.ria__section {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid var(--Primary, #bd0027);
  &--title {
    color: var(--Gray-1, #333);
    font-size: clamp(16px, 3vw, 16px);
    font-weight: 600;
    @media (width <= 768px) {
      text-align: center;
    }
  }
  &--description {
    color: var(--Gray-1, #333);
    font-size: 12px;
    font-weight: 300;
  }
  &--caption {
    padding: 16px;
    background: #fffae0;
  }
  &__caption {
    display: flex;
    flex-direction: column;
    gap: 12px;
    > span {
      color: var(--Gray-1, #333);
      font-size: 14px;
      font-weight: 600;
    }
    > p {
      color: var(--Gray-1, #333);
      font-size: 14px;
      font-weight: 300;
    }
  }
}
.payment__section {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  &--title {
    color: var(--Gray-1, #333);
    font-size: clamp(18px, 3vw, 20px);
    font-weight: 600;
    @media (width <= 768px) {
      text-align: center;
    }
  }
}
.refund__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--Grey-5, #e0e0e0);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);

  @media (width <= 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 24px 8px;
  }

  &--action {
    display: flex;
    align-items: center;
    gap: 16px;
    &__left {
      font-size: clamp(16px, 3vw, 18px);
      font-weight: 600;
      max-width: 60%;
      margin-right: auto;
      @media (width <= 768px) {
        max-width: 100%;
        text-align: center;
      }
    }
  }
  &--caption {
    padding: 16px;
    background: #fffae0;
  }
}
.RADIANCE .documents__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &--title {
    color: var(--Primary, #bd0027);
    font-size: clamp(18px, 3vw, 20px);
    font-weight: 600;
  }
  &--content {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 16px;
    @media (width <= 768px) {
      grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    }
    @media (width <= 548px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
    &__card {
      display: flex;
      flex-wrap: wrap;
      padding: 24px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--Grey-5, #e0e0e0);
      background: #fff;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);

      @media (width <= 548px) {
        flex: auto;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 16px;
      }

      &_title {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        @media (width <= 548px) {
          flex-direction: row;
          align-items: flex-start;
          text-align: left;
          color: var(--Gray-1, #333);
        }

        span {
          font-size: clamp(12px, 3vw, 16px);
          font-weight: 500;
        }
      }
      &_action {
        color: var(--Primary, #bd0027);
        font-size: 14px;
        text-decoration-line: underline;
      }
    }
  }
}
.contract_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  ._subscription_wrapper--description {
    color: var(--Gray-3, #828282);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  ._product_card--title {
    color: var(--Primary, #bd0027);
    font-size: 14px;
    font-weight: 600;
  }
  ._product_card--content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .product_name {
      color: #000;
      font-size: 31px;
      font-weight: 700;
    }
    .product_price {
      color: var(--Primary, #bd0027);
      font-size: 30px;
      font-weight: 700;
      &--month {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  ._product_card--caption {
    color: #5b5a5a;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .b2c__area--actions.justify--center {
    justify-content: center !important;
    button {
      transform: translateX(0px) !important;
    }
  }
}
.members__recap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .--title {
    color: var(--Primary, #bd0027);
    font-weight: 600;
    @media (width <= 767px) {
      text-align: center;
    }
  }

  &--child {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (width <= 767px) {
      align-items: center;
      text-align: center;
    }

    .--item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      @media (width <= 767px) {
        text-align: center;
        flex-direction: column;
        gap: 4px;
      }
      .--fullname {
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 600;
      }

      .child {
        font-style: italic;
        font-weight: 400;
        color: var(--Primary, #bd0027);
      }

      .--info {
        display: flex;
        align-items: center;
        gap: 6px;

        .label {
          font-size: clamp(14px, 3vw, 16px);
          font-weight: 400;
        }
        .value {
          font-size: clamp(14px, 3vw, 16px);
          font-weight: 600;
        }
      }
    }
    // .primary {
    //   color: #bd0027;
    // }
    // > span:first-child {
    //   color: var(--Primary, #bd0027);
    //   font-weight: 600;
    // }
  }
}
.justification__section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
  @media (width <= 767px) {
    width: 100%;
  }
  &--child {
    display: flex;
    flex-direction: column;
    gap: 12px;
    button {
      padding: 0;
      text-decoration: underline;
    }
    &__title {
      color: #bd0027;
      font-size: clamp(16px, 3vw, 18px);
      font-weight: 600;
      @media (width <= 768px) {
        text-align: center;
      }
    }

    .upload {
      display: flex;
      align-items: center;
      gap: 8px;

      @media (width <= 768px) {
        flex-direction: column;
        justify-content: center;
        .label {
          display: none;
        }
      }

      .primary--link {
        color: $c-radiance-template;
      }
    }

    .ant-upload-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .ant-upload-list {
        min-width: 80%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .file-dragger--wrapper {
      display: flex;
      padding: 24px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 2px dashed #e0e0e0;
      background: #fff;
      .ant-upload-list-item {
        margin-top: 0 !important;
      }
    }

    &__caption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #b5b5b5;
        font-size: clamp(6px, 3vw, 12px);
        font-weight: 400;

        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
.cost-updated {
  max-width: 550px;
  &__title {
    font-size: 20px;
    color: $c-radiance-template;
    font-weight: 700;
    margin: 0;
    text-align: center;
  }
  &__text {
    font-size: 14px;
    text-align: center;
  }
  &__subtitle {
    font-size: 16px;
    text-align: center;
  }
  &__frame-container {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    text-align: center;
  }
  &__cost {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h4 {
      font-size: 16px;
      color: $c-radiance-text-gray;
    }
    &--active {
      h4 {
        color: $c-radiance-template;
      }
    }
  }
  &__frame {
    border: 1px solid $c-radiance-text-gray;
    border-radius: 12px;
    padding: 8px 16px;
    &--active {
      border: 1px solid $c-radiance-template;
    }
    &--effectiveDate {
      color: $c-radiance-text-gray;
      font-size: 14px;
      padding-bottom: 24px;
      &--active {
        color: $c-radiance-template;
      }
    }
    &--insurance {
      color: $c-radiance-text-gray;
      font-size: 16px;
      font-weight: 700;
      &--active {
        color: $c-radiance-text-black;
      }
    }
    &--cost {
      color: $c-radiance-text-gray;
      font-size: 24px;
      &--active {
        color: $c-radiance-template;
      }
    }
  }
  &__actions {
    button {
      border-radius: 100px;
    }
    display: flex;
    justify-content: center;
    gap: 8px;
    .primary {
      background-color: $c-radiance-template;
      color: $c-white;
      border: 2px solid transparent;
      border-radius: 100px;

      &:hover {
        color: $c-radiance-template !important;
        background-color: $c-radiance-button-hover !important;
        border: 2px solid $c-radiance-template !important;

        @media (width <= 768px) {
          background-color: $c-radiance-template !important;
          border: 2px solid $c-radiance-template !important;
          color: $c-white !important;
        }
      }
      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover {
        background-color: $c-radiance-template;
        color: $c-white;
        opacity: 0.3;
      }
    }
  }
  @media (max-width: 542px) {
    max-width: 400px;
    &__title {
      font-size: 16px;
    }
    &__text {
      font-size: 14px;
    }
    &__subtitle {
      font-size: 12px;
    }

    &__cost {
      gap: 8px;
      h4 {
        font-size: 12px;
      }
    }
    &__frame {
      &--active {
        border: 1px solid $c-radiance-template;
      }
      &--effectiveDate {
        font-size: 12px;
      }
      &--insurance {
        font-size: 14px;
      }
      &--cost {
        font-size: 16px;
      }
    }
  }
}
.error-message {
  font-size: 12px;
  color: #fb2222;
}
