.template-product-list--container {
  & .card-skeleton--container {
    background: $c-white;
    border-radius: 8px;

    &.highlighted {
      border: 2px solid $c-blue-light2;
      // box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    }
  }

  & .card-skeleton--wrapper {
    padding: 24px;
    & .slider-line--wrapper {
      margin-top: 24px;
    }
  }

  & .title--md {
    font-weight: 700;
    font-size: 16px;
    color: $c-dark-3;
  }

  & .levels-coverage--container {
    display: grid;
    gap: 8px;
  }

  & .levels-coverage--wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 150px 1fr;
    gap: 8px;
  }

  & .row-divider {
    height: 1px;
    width: 100%;
    border-top: 1px solid $c-border;
    margin: 16px 0;
  }

  & .notice {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dashed #e6ecf5;
    border-radius: 8px;
    color: $c-border;
    font-weight: 600;
    font-size: 14px;
  }

  & .reverse {
    & .input__wrapper {
      flex-direction: column-reverse !important;
    }
  }

  & .custom-input__wrapper {
    & .divider-input {
      height: 32px;
      display: flex;
      align-items: center;
      width: 100%;

      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background: $c-gray-light4;
      }
    }
  }

  & .label-coverage {
    font-weight: 400;
    font-size: 14px;
    color: $c-gray-light4;
  }

  .gray--outlined {
    font-size: 12px;
    height: 32px;
  }

  .card-product--container {
    background: $c-white;
    border-radius: 8px;
    position: relative;

    & .ant-select-selector {
      min-width: 90px;
      display: flex;
      align-items: center;
      & .ant-select-selection-item {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
      }
    }

    & .right--wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }

    &.default {
      border: 2px solid $c-gray-light6;
    }

    &.added {
      border: 2px solid $c-blue-light2;
    }

    &.disabled {
      border: 2px solid $c-blue-light2;
      opacity: 0.3;
    }

    &.compare {
      border: 1px solid $c-blue-light2;
      border-radius: 4px;
    }

    &.default,
    &.added,
    &.disabled {
      & .card-product--wrapper {
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
      }

      & .product-info {
        display: flex;
        align-items: center;
        gap: 16px;
        flex: 1;
      }

      & .description--wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-width: 100px;
      }

      & .action--wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        // margin-right: -16px;
        // margin-bottom: -40px;
        gap: 16px;
        & .warranty {
          background: $c-primary;
          color: $c-white;
          padding: 6px 12px;
          border-radius: 8px 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        & .action {
          width: 36px;
          height: 36px;
          background: transparent;
          border: none;
          cursor: pointer;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:has(.add) {
            background-color: $c-primary;

            & span {
              color: $c-white;
            }
          }

          &:has(.remove) {
            background-color: $c-primary-light;
            & span {
              color: $c-primary;
            }
          }
        }
      }
    }

    & .product-info__img {
      display: flex;
      width: 124px;
      align-items: center;
      img {
        max-height: 80px;
        object-fit: contain;
      }
    }

    &.compare {
      & .card-product--wrapper {
        padding: 16px 24px;
      }

      & .product-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;
      }

      & .description--wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-wrap: wrap;
        flex: 1;
      }
    }

    & .price--wrapper,
    & .text--wrapper {
      display: grid;
    }

    & .ant-image-mask {
      display: none;
    }

    & .text-md {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $c-dark-3;
      margin: 0;
    }

    & .text-sm {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $c-gray-light4;
    }

    & .cost-per-month {
      font-weight: 700;
      font-size: 16px;
      color: $c-blue-light2;
    }

    & .cost-all {
      color: $c-dark-3;
    }
  }
}

.locked {
  & .slider-dot--wrapper,
  & .card-product--wrapper {
    pointer-events: none;
  }
  & .card-skeleton--wrapper .button__wrapper {
    display: none;
  }
}

.select_200 {
  width: 200px;
}

.levels-coverage--wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr;
  gap: 8px;

  .img {
    height: 24px;
    width: 24px;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $c-dark-3;
  }
}
