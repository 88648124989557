.contact_button {
  position: fixed;
  z-index: 99;
  right: -108px;
  top: 50%;
  transition: all 0.3s ease-in-out;
  &:hover {
    right: 0;
    top: 50%;
  }
  button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.name--client {
  border-bottom: 1px solid $c-text-sec;
  font-weight: 600;
  transition: all ease 0.4s;
  color: $c-text-sec;
  text-transform: uppercase;

  &:hover {
    color: $c-primary;
    border-bottom: 1px solid $c-primary;
    transition: all ease 0.4s;
  }
}
.view-contact {
  display: flex;
  align-items: center;
  justify-content: center;

  // border-radius: 4px;
  // background: #eff3f8 !important;
  // color: #858585 !important;
  // font-weight: 600;
  // padding: 3px 10px !important;
  // height: auto;
}
.contact__drawer--container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__child {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &--header {
      background: #f7f7f8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      font-weight: 800;
      padding: 12px 16px;
      margin-top: 8px;
    }
    &--info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-left: 16px;
      &__title {
        color: $c-gray;
      }
      &__content {
        font-weight: 600;
      }
    }
  }
}

.email__edit {
  &--display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;

    .input__wrapper {
      flex-grow: 1;
    }
  }
}

.contact__drawer {
  width: 40%;
  .ant-tabs-nav-list {
    justify-content: left;
    gap: 12px;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: #efefef;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    .ant-tabs-tab:hover,
    .ant-tabs-tab:focus {
      color: #000;
    }
    .ant-tabs-tab {
      color: #c9cbd0;
      margin: 0 !important;
      width: auto;
      background: transparent;
      border-bottom: none !important;
    }
    .ant-tabs-ink-bar {
      background: #000;
    }
    .ant-tabs-tab-active {
      color: #000;
      .ant-tabs-tab-btn {
        color: #000;
      }
    }
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    gap: 4px;
    justify-content: left;
    gap: 16px;
    > button {
      margin: 0;
    }
    .ant-drawer-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 22px;
      }
    }
  }
  .ant-drawer-header {
    border-bottom: 0 !important;
    padding: 24px 24px 8px 24px !important;
  }
  .projects__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .single-project {
      border-radius: 8px;
      border: 1px solid #eaeaea;
      background: #fff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__detail {
        display: flex;
        flex-direction: column;
        &--header {
          display: flex;
          justify-content: space-between;
          gap: 8px;

          .left {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
      &__author {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          flex: 1;
          width: 100%;
          .select__wrapper {
            width: 100%;
          }
        }
        &--effectiveDate {
          display: flex;
          gap: 4px;
          align-items: center;
          font-weight: 400;
          &.expired {
            font-weight: 500;
            color: $c-red;
          }
        }
        &--name {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;

          label {
            color: $c-gray;
          }

          .value {
            font-weight: 600;
          }
        }
      }

      &__link {
        padding-top: 8px;
        margin-top: 8px;
        border-top: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 8px;
        color: $c-primary;
        font-weight: 500;
      }
    }
  }
}
