.user-delete {
  display: flex;
  flex-direction: column;
  gap: 32px;
  &__title {
    font-size: 24px;
    font-weight: 700;
    color: $c-danger;
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 700;
  }
  &__row {
    align-items: center;
    padding: 12px 24px;
    &:not(:last-child) {
      border-bottom: 1px solid $c-border;
    }
  }
}
