.loader__wrapper {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
  .loading {
    padding: 32px;
    background-color: rgba($color: $c-primary, $alpha: 0.06);
    border-radius: 4px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loader__radiance {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  div {
    height: 160px;
    width: 160px;
    padding: 16px;
  }
}
