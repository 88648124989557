.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;

  @media (min-width: 476px) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (min-width: 768px) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (min-width: 992px) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (min-width: 476px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 100%;
  }

  @media (min-width: 1391px) {
    padding-right: 24px;
    padding-left: 24px;
    max-width: calc(100% - 300px) !important;
  }
}

.container--medium {
  max-width: 1199px;
  margin: 0 auto;

  @media (max-width: 1399px) {
    max-width: 1140px;
  }

  @media (max-width: 1299px) {
    max-width: 1099px;
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 576px) {
    max-width: 100%;
    padding: 0 16px;
  }

  @media (max-width: 400px) {
    max-width: 100%;
    padding: 0 16px;
  }
}
.layout {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  @media (max-width: 1391px) {
    gap: 0;
  }
}
