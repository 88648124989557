.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 24px;
  img {
    align-self: center;
    height: 24px;
    object-fit: contain;
  }
  &__body {
    width: 96%;
    max-width: 500px;
    background-color: $c-white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 24px;
    .primary {
      width: 100%;
    }
    &--title {
      text-align: center;
      margin-bottom: 16px;
    }
  }
  &__link {
    color: $c-gray;
    cursor: pointer;
    text-decoration: underline;
  }
  &__deactivate {
    &--title {
      font-size: 28px;
      font-weight: 700;
      color: $c-red;
    }
    &--text {
      text-align: center;
      font-size: 18px;
      color: $c-text-sec;
      max-width: 400px;
    }
    &--link {
      padding-top: 40px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      color: $c-primary;
    }
  }
}
