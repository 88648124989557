.subscription-additional-information--wrapper {
  padding-bottom: 80px;
  &__action {
    position: sticky;
    bottom: 20px;
    z-index: 9;
  }
}

.subscription__card {
  border: 1px solid $c-gray-light;
  border-radius: 8px;

  &--head {
    background-color: $c-gray-light;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .infos {
      display: flex;
      align-items: center;
      gap: 8px;

      .item {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $c-gray-dark;
      }
    }

    .status {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $c-primary;
    }
  }

  &--body {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .product {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      img {
        width: 100px;
        object-fit: contain;
      }

      .infos {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h4 {
          font-size: clamp(14px, 3vw, 16px);
          font-weight: 600;
        }
        span {
          color: $c-gray-dark;
          font-size: clamp(12px, 3vw, 14px);
        }
        .tag {
          background-color: $c-gray-light;
          color: $c-gray-dark;
          padding: 2px 4px;
          width: max-content;
          border-radius: 2px;
        }
      }
    }

    .pricing {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .price {
        span {
          color: $c-primary;
          font-weight: 800;
          font-size: clamp(18px, 3vw, 24px);

          sup {
            font-weight: 600;
          }
        }
        small {
          font-weight: 400;
          color: $c-gray-dark;
        }
      }
      .price--info {
        color: $c-gray-dark;
        span {
          color: $c-black;
        }
      }
    }
  }

  &--action {
    padding: 0 16px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}

.generate-link-modal {
  max-width: 506px;
  .ant-modal-header {
    padding-bottom: 42px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .modal-text {
    margin-top: 16px;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 42px;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: $c-primary;
    border-color: $c-primary;
  }

  .radio-item {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid $c-border;
    &:has(.ant-radio-wrapper-checked) {
      background-color: $c-primary-background;
      border: 1px solid $c-primary;
      .radio-title {
        color: $c-primary;
      }
    }
  }

  .radio-description {
    display: block;
    margin-left: 24px;
    color: $c-text-sec;
  }

  .generate-button {
    margin-top: 24px;
    width: 100%;
  }

  .copy-send {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__success {
      display: flex;
      gap: 16px;
      align-items: center;
      color: $c-success;
    }
    &__link {
      padding: 8px 12px;
      background: $c-background;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: noWrap;
      font-size: 12px;
      border-radius: 4px;
    }
    &__actions {
      display: flex;
      justify-content: center;
      gap: 8px;
    }
  }
}
