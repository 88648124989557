.template-product-filters--container {
  & .template-product-filters--wrapper {
    display: grid;
    gap: 16px;
    max-width: 780px;
    margin: 0 auto;
  }

  & .levels--container {
    padding: 40px 0 16px 0;
    display: grid;
    gap: 16px;

    & .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $c-gray-light7;
      border-radius: 4px;
      padding: 8px 4px;
      background: $c-gray-light6;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    & .levels--wrapper {
      display: grid;
      grid-template-columns: 2fr 6fr;
      row-gap: 8px;
      column-gap: 12px;
      &--levels {
        display: flex;
        row-gap: 8px;
        column-gap: 12px;
        & > div {
          flex: 1;
        }
      }
    }

    & .levels-coverage--container {
      display: grid;
      gap: 8px;

      & .coverage-trailing--wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        & .img {
          height: 32px;
          width: 32px;
          margin: 0;
        }

        & .label {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $c-dark-3;
          text-align: left;
        }
      }

      & .custom-slider--wrapper {
        display: grid;
        align-items: center;
        padding: 0 5%;
        .ant-radio-wrapper .ant-radio-inner {
          background-color: $c-border;
          border-color: $c-white;
          width: 12px;
          height: 12px;
        }
        .ant-radio-checked .ant-radio-inner {
          border: 4px solid $c-white;
          background-color: $c-primary;
          color: $c-primary;
        }
        .ant-radio-checked .ant-radio-inner,
        .ant-radio:hover .ant-radio-inner {
          width: 20px;
          height: 20px;
        }
        .ant-radio-checked .ant-radio-inner:after {
          background-color: $c-primary;
        }
      }
    }

    & .levels-coverage--wrapper {
      display: grid;
      grid-template-columns: 2fr 6fr;
      background: $c-background;
      padding: 8px 16px;
      border-radius: 8px;
    }
  }

  & .budget--container {
    background: $c-white;
    border-radius: 8px;
    padding: 24px;

    & .budget-action--wrapper {
      padding: 32px 40px 18px 40px;
      background: $c-gray-light6;
      border-radius: 8px;
    }

    & .trailing--wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      & .img {
        width: 32px;
        height: 32px;
      }

      & .text {
        display: grid;
        gap: 8px;

        & .main {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $c-dark-3;
        }

        & .second {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: $c-gray-light4;
        }
      }
    }

    & .custom-input__wrapper {
      & .divider-input {
        height: 32px;
        display: flex;
        align-items: center;
        width: 100%;

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          background: $c-gray-light4;
        }
      }
    }
  }
}

.locked {
  & .levels--container,
  & .budget--container {
    pointer-events: none;
  }
}
