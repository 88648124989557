.product__card {
  display: flex;
  align-items: center;
  gap: 16px;

  &--image {
    height: 160px;
    width: 160px;
    object-fit: contain;
    aspect-ratio: 1/1;
  }
  &--content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &--name {
    font-size: clamp(20px, 3vw, 24px);
    font-weight: 700;
  }
  &--price {
    display: flex;
    gap: 4px;
    align-items: center;

    .label {
      font-size: clamp(12px, 3vw, 14px);
      color: $c-gray;
    }
    .value {
      font-size: clamp(12px, 3vw, 14px);
      color: $c-dark;
    }
  }

  &--vertical {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .product__card--content {
      align-items: center !important;
      text-align: center !important;
    }
  }
}

.btn--modify button {
  padding: 0 !important;
  height: auto;
}

//
.action__stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  &:not(:has(.action__stepper--previous)) {
    justify-content: flex-end;
  }
}

//
.client__information {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &--title {
    font-size: clamp(14px, 3vw, 14px);
    font-weight: 600;
    color: $c-primary;
  }

  &--content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--item {
    display: flex;
    font-size: clamp(12px, 3vw, 14px);
    flex-direction: column;
    gap: 4px;

    & > .client__information--label {
      font-size: clamp(12px, 3vw, 12px);
      color: $c-gray;
      font-weight: 500;
    }

    & > .client__information--value {
      font-size: clamp(14px, 3vw, 14px);
      font-weight: 600;
    }
  }

  .action {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  &--modify {
  }

  &--client {
    & > .client__information--title {
      color: $c-gray;
      font-weight: 600;
      font-size: clamp(14px, 3vw, 16px);
    }
  }

  &--default {
  }
}

//

.offer__items {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--title {
    font-weight: 600;
    font-size: clamp(12px, 3vw, 14px);
    color: $c-dark;
  }

  &--list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--item {
    display: flex;
    align-items: flex-start;
    gap: 4px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &--value {
    font-size: clamp(12px, 3vw, 12px);
    color: $c-text;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.offer__items--light {
    background-color: rgba($c-primary, 0.04);
    padding: 8px;
    border-radius: 8px;
  }

  &.offer__items--dark {
    background-color: rgba($c-gray, 0.08);
    padding: 8px;
    border-radius: 8px;
  }
}

//
.pricing__item {
  background: rgba($c-primary, 0.06);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;

  .--header {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .--top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .--title {
    color: $c-primary;
    font-size: clamp(18px, 3vw, 20px);
    flex-grow: 1;
  }

  .--description {
    color: $c-gray;
    font-size: clamp(12px, 3vw, 12px);
  }

  .--price {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .main {
      font-size: clamp(24px, 3vw, 32px);
      color: $c-text;
      font-weight: bold;
    }
    .sub {
      font-size: clamp(12px, 3vw, 12px);
      color: $c-gray;
    }
  }

  &--compact {
    background: $c-secondary;

    .--title {
      color: $c-white;
    }

    .--price {
      .main {
        color: $c-white;
      }
      .sub {
        color: $c-gray;
      }
    }
  }

  &--detailed {
    background: transparent;
    border-radius: 0;
    padding: 0;
  }

  &--highlighted {
    background: rgba($c-primary, 0.06);
  }
}

.pricing__card {
  background: rgba($c-primary, 0.06);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &--subscribe {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0 0 0;

    .button__wrapper {
      width: 100%;
    }
  }

  &--summary {
    display: flex;
    gap: 16px;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: $c-gray;

      svg {
        flex-shrink: 0;
      }

      strong {
        font-weight: 700;
        color: $c-text;
      }
    }
  }

  &--details {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid #e0e0e0;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    grid-gap: 24px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
      grid-gap: 16px;
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .box {
      flex-grow: 1;
    }
  }
}

.infos--summary {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: $c-gray;

    svg {
      flex-shrink: 0;
    }

    strong {
      font-weight: 700;
      color: $c-text;
    }
  }
}

.pricingVertical__card {
  background: rgba($c-white, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .content {
    padding: 0 16px 16px;
  }

  &--summary {
    display: flex;
    gap: 8px;
    flex-direction: column;
    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: $c-gray;

      svg {
        flex-shrink: 0;
      }

      strong {
        font-weight: 700;
        color: $c-text;
      }
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
    margin: 16px 0;
    border-top: 1px solid #e0e0e0;
  }

  &--action {
  }
}

.documentDownload__area {
  display: flex;
  flex-direction: column;
}

.documentDownload__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  &--info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &--details {
    display: flex;
    align-items: center;
    gap: 8px;

    .--name {
      font-size: clamp(12px, 3vw, 14px);
      color: $c-text;
    }
    .--size {
      font-size: clamp(12px, 3vw, 14px);
      color: $c-gray;
    }
  }
  &--action {
    background: rgba($c-primary, 0.06);
  }
}

.payment__details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &--section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .key {
      font-size: clamp(14px, 3vw, 14px);
      font-weight: 600;
      color: $c-gray-dark;
    }
    .value {
      font-size: clamp(12px, 3vw, 12px);
      color: $c-text;
      font-weight: 500;
      small {
        font-size: clamp(14px, 3vw, 14px);
        color: $c-gray-dark;
        font-weight: 300;
      }
      span {
        font-size: clamp(14px, 3vw, 14px);
        color: $c-text;
      }
    }
  }
}

.productPurchase__details {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  &--image {
    height: 80px;
    width: 80px;
    border-radius: 8px;
    aspect-ratio: 1 / 1;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--title {
    font-size: clamp(18px, 2vw, 24px);
    font-weight: bold;
    color: $c-text;
  }

  &--list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--item {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    gap: 4px;
  }
  &--label {
    font-weight: 700;
    color: $c-gray-dark;
    font-size: clamp(12px, 2vw, 12px);
  }

  &--value {
    font-weight: 500;
    color: $c-text;
    font-size: clamp(12px, 2vw, 14px);
  }
}

//

.select__plan {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  gap: 16px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    gap: 16px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  &--item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    border: 1px solid $c-gray-light;
    border-radius: 8px;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background-color: $c-white;
    cursor: pointer;

    input[type="checkbox"] {
      cursor: pointer;
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      border: 1px solid $c-grey-b9;
      border-radius: 4px;

      outline: none;
      transition: border-color 0.3s;
      position: relative;
      margin-top: 4px;
    }

    input[type="checkbox"] {
      cursor: pointer;
      min-width: 20px;
      min-height: 20px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 20px;
      height: 20px;
      border: 1px solid $c-grey-b9;
      border-radius: 4px;
      outline: none;
      transition: border-color 0.3s;
      position: relative;
    }

    input[type="checkbox"]::before {
      content: "";
      color: $c-primary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      width: 50%;
      height: 50%;
      transition: opacity 0.3s;
      background-image: url("../../assets/icons/check_small.svg"); /* Replace with the path to your custom SVG icon */
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    input[type="checkbox"]:checked {
      &:before {
        opacity: 1;
      }
      border: 1px solid $c-primary;
      background-color: rgba($c-primary, 0.1);
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: 100%;

      .title {
        color: $c-gray-dark;
        font-size: clamp(14px, 2vw, 24px);
        font-weight: 600;
      }

      .description {
        color: $c-gray-dark;
        font-size: clamp(12px, 1.8vw, 14px);
        flex: 1;
      }

      .price {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        gap: 2px;
        h4 {
          color: $c-gray-dark;
          font-size: clamp(16px, 2vw, 24px);
          font-weight: 900;
        }

        span {
          color: $c-gray-dark;
          font-size: clamp(12px, 1.8vw, 12px);
        }
      }
    }

    &.select__plan--selected {
      border-color: $c-primary;

      .title {
        color: $c-primary;
      }

      .description {
        color: $c-primary;
      }

      .price {
        h4 {
          color: $c-primary;
        }

        span {
          color: $c-gray;
        }
      }
    }
  }
}
