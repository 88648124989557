.select__wrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }
  &.__error {
    .label__area {
      color: #f26262 !important;
    }
    .ant-select-selector {
      background: rgba(242, 98, 98, 0.1) !important;
    }
    .ant-select-selection-placeholder {
      color: #f26262 !important;
    }
  }

  .label__area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 400;

    & .label__icon {
      display: flex;
      align-items: center;
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
    border-radius: 8px;
  }

  &.light .ant-select:not(.ant-select-customize-input) {
    & .ant-select-selector {
      background-color: #fff !important;
    }
  }

  & .ant-select:not(.ant-select-customize-input) {
    & .ant-select-selector {
      background-color: $c-background !important;
      border: none;
    }
  }

  & .ant-select-selector {
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    & .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & .ant-select-selection-overflow-item {
    & .ant-select-selection-item {
      background: $c-white !important;
      border: 1px solid $c-gray !important;
    }
  }

  &--subtext {
    width: 100%;
    .ant-select-single.ant-select-lg,
    .ant-select-single {
      height: auto;
    }
    .ant-select-single.ant-select-lg
      .ant-select-selector
      .ant-select-selection-item {
      padding: 8px 0;
      line-height: normal;
    }
    .option {
      &__label {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 4px;
      }
      &__status {
        font-size: 12px;
        color: $c-text-sec;
        padding: 0;
      }
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 55px;
    }
  }
}

.group {
  display: flex;
  &--horizental > div {
    flex: 1 1 0%;
  }
  &__content {
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 8px;
    }
    &--label {
      font-weight: 600;
    }
  }
  &__select .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 4px !important;
      padding: 0 8px !important;
      background: #eff3f8 !important;
      border-color: #e8e8e8 !important;
      align-items: center;
      height: 40px;
    }
    &.dark .ant-select-selector {
      background: #fff !important;
      border-color: rgb(226, 226, 226) !important;
    }
  }
}

.select--wrapper {
  & .ant-select {
    width: 100%;
  }
}

.select__tag {
  &--wrapper {
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      padding: 0;
      background-color: transparent !important;
      box-shadow: none;
      height: 32px;
    }
    .ant-select-focused:where(
        .css-dev-only-do-not-override-byeoj0
      ).ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      box-shadow: none;
    }

    .ant-select-single.ant-select-lg.ant-select-show-arrow
      .ant-select-selection-item {
      padding-inline-end: 0;
      margin-right: 32px;
      border-radius: 100px;
      padding: 4px 12px;
      // background-color: $c-background;
      font-size: clamp(12px, 3vw, 14px);
      font-weight: 500;
      line-height: 22px;
    }

    .ant-select-dropdown .ant-select-item {
      padding: 4px 0 !important;
    }

    :where(.css-dev-only-do-not-override-ni1kz0).ant-select-dropdown
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }

    :where(.css-dev-only-do-not-override-ni1kz0).ant-select-dropdown
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
    .ant-select-focused:where(
        .css-dev-only-do-not-override-ni1kz0
      ).ant-select-outlined:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer)
      .ant-select-selector {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .ant-select-selection-item {
    background-color: $c-background;
    border-radius: 100px;
  }

  .item {
    .tag {
    }
  }
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $c-background !important;
}

.ant-select-item-option:hover {
  background-color: $c-background !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $c-background !important;
}
