.b2c_container {
  background-color: $c-white;
  padding: 42px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  display: flex;
  // max-width: 800px;
  &.bg-transparent {
    background: transparent !important;
  }
  max-width: 1200px;
  margin: auto;
  min-height: 500px;
  &--header {
    margin-bottom: 48px;
    &__title {
      text-align: center;
      font-size: 32px;
      font-weight: 900;
      line-height: 100%;
    }
    &__subtitle {
      color: $c-default-text-gray-light;
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
    }
  }

  &--content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 16px;
    &.columns {
      display: flex;
      flex-direction: column;
    }
    label.status {
      width: 100%;
      height: auto;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid $c-default-template;
      border-radius: 4px;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 16px;
      font-weight: 900;
      display: flex;
    }

    &__children-count {
      width: 100%;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      flex-direction: row;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 50px;
      display: flex;
      grid-area: span 1 / span 2 / span 1 / span 2;
      .image_container {
      }
      .option_container {
        width: 100%;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
        justify-content: center;
        align-items: center;
        display: flex;
        > label {
          width: 100%;
          height: 40px;
          text-align: center;
          cursor: pointer;
          border: 2px solid $c-default-template;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          margin-left: 0;
          padding-left: 0;
          font-size: 16px;
          display: flex;
        }
      }
    }
  }
  &--actions {
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    display: flex;
    &__next {
      color: $c-white;
      background-color: $c-default-template;
      &:not(:disabled):hover {
        color: $c-white !important;
        filter: brightness(1.5);
      }
    }
    &__subscribe {
      color: $c-white;
      background-color: $c-default-success;
      &:not(:disabled):hover {
        color: $c-white !important;
        filter: brightness(1.2);
      }
    }
  }

  &--infos {
    width: 100%;
    max-width: 600px;
    justify-content: space-between;
    margin-top: 40px;
    display: flex;
    color: $c-default-template;
    > div {
      width: 100%;
      text-align: justify;
      border: 3px solid $c-default-template;
      border-radius: 4px;
      padding: 18px;
      line-height: 16px;
    }
  }
}

.need_container {
  grid-area: span 1 / span 2 / span 1 / span 2;
  &--header {
    width: 100%;
    max-width: 600px;
    align-items: flex-end;
    display: flex;
    gap: 8px;
    h3 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 12px;
    }
  }
  &--content {
    width: 100%;
    height: 48px;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    background-color: $c-default-border;
    border-radius: 4px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 0;
    margin-right: 0;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    label {
      width: 100%;
      height: 40px;
      text-align: center;
      cursor: pointer;
      border: 1px solid $c-white;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      font-size: 16px;
      display: flex;
      span {
        font-weight: 900;
      }
      &.selected {
        background-color: $c-default-template;
        color: $c-white;
        border-style: none;
        border-width: 1px;
        border-radius: 5px;
      }
    }
  }
}

.product-card {
  width: 100%;
  height: max-content;
  background-color: $c-white;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 0;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;

  &__needs {
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
  }

  .product-card--price_container {
    h3 {
      width: auto;
      margin-right: 0;
      color: $c-default-text-gray;
      font-size: 16px;
      font-weight: 900;
      span {
        color: $c-default-product-blue;
        font-size: 24px;
        font-weight: 900;
      }
    }
    p {
      width: auto;
      color: $c-default-text-gray-light;
      font-size: 12px;
      font-weight: 500;
      span {
        font-weight: 900;
      }
    }
  }
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  .product-card__title {
    width: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    > label {
      justify-content: flex-start;
      margin-top: 0;
      padding-left: 0;
      display: flex;
    }
    .product-card--image {
      width: 100px;
      object-fit: contain;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0;
      margin-right: 0;
      display: flex;
      position: relative;
    }
    .product-card--detail {
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      flex-direction: column;
      display: flex;
    }
    .product-card--title {
      h3 {
        font-weight: 900;
        font-size: 16px;
      }
      p {
        color: $c-default-text-gray-light;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .detailcontainer {
    .product-card--warranty {
      flex-direction: row;
      display: flex;
      align-items: center;
      .niveau-besoin-micro {
        height: auto;
        object-fit: fill;
        background-color: $c-white;
        border-radius: 4px;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        display: flex;
      }
    }
    .actions {
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      display: flex;
      font-size: 12px;
      gap: 4px;
      .row {
        cursor: pointer;
        display: flex;
        gap: 4px;
      }
    }
  }
}

.levels {
  width: 12px;
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 2px;
  &__bar {
    width: 6px;
    height: 18px;
    background-color: rgba($color: $c-default-product-blue, $alpha: 0.3);
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &--filled {
      background-color: $c-default-product-blue;
    }
  }
  &__icon {
    width: 12px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;

    img {
      width: 12px;
      max-height: 16px;
      object-fit: contain;
    }
  }
}

.b2c-radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  .label__area {
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 700;
  }
  .ant-radio-button-wrapper {
    background: transparent;
    flex: 1;
    height: auto !important;
    padding: 8px;
    text-align: center;
    &:hover {
      color: $c-red;
    }
  }
  .ant-radio-button-wrapper:has(:checked) {
    opacity: 1;
    border: 1px solid $c-default-template;
  }
  .ant-radio-button-wrapper-checked {
    color: $c-default-template;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background: $c-default-border;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: $c-default-template;
  }
  &:has(.label-with-image) {
    .ant-radio-button-wrapper {
      padding: 18px 24px;
      background: transparent;
      opacity: 0.5;
      &:hover {
        color: $c-red;
      }
    }
    .ant-radio-button-wrapper:not(:has(:checked)) {
      border: none;
    }
    .ant-radio-button-wrapper:has(:checked) {
      opacity: 1;
      border: 1px solid $c-default-template;
    }
    .ant-radio-button-wrapper-checked {
      color: $c-default-template;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      background: transparent;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: transparent;
    }
    label {
      img {
        height: 124px;
      }
      input[type="radio"] {
        box-sizing: border-box;
        padding: 0;
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }
}

.b2c-subscription_stepper {
  width: 100%;
  max-width: 600px;
  margin-bottom: 24px;
  > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > li {
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
      color: rgb(218, 218, 218);
      &.active {
        color: $c-red;
        > span:first-child {
          border-color: $c-red;
        }
      }
      > span:first-child {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: rgb(218, 218, 218);
        border-width: 1px;
        border-style: solid;
        border-radius: 9999px;
        text-align: center;
      }
    }
  }
}
.b2c-subscription__edit {
  display: flex;
  width: 100%;
  max-width: 740px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
// trash
.subscription-content {
  margin-top: 12px;
  width: 100%;
  display: flex;
  gap: 8px;
  .left-side {
    width: 60%;
    background-color: $c-white;
    padding: 16px;
    border-radius: 8px;
    .left-side_title {
      font-size: 24px;
      font-weight: 800;
      display: block;
      margin-bottom: 24px;
    }
  }
  .right-side {
    width: 40%;
    background-color: $c-white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    &_offer {
      font-weight: 800;
      color: $c-default-gray;
      font-size: 18px;
    }
    &_product {
      margin-top: 16px;
      font-weight: 800;
      color: $c-red;
      font-size: 24px;
    }
  }
}

.collaped_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  > &--child {
    background-color: $c-white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $c-default-border;
    box-shadow: -7px 7px 17px 0px rgba(0, 0, 0, 0.04);
    &_title {
      font-weight: 800;
      font-size: 24px;
      margin-bottom: 16px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .danger--underline {
        color: $c-default-template;
        text-decoration: underline;
        text-align: right;
        font-weight: 700;
        padding: 0;
      }
    }
    &_content {
      padding: 16px 0;
      display: none;
    }
  }
  &--child.false {
    .collaped_container--child_title {
      margin-bottom: 0;
      color: $c-default-border;
      font-size: 20px;
      padding: 12px 0;
      font-weight: 700;
    }
  }
  > &--child {
    &.active {
      .collaped_container--child_content,
      .collaped_container--actions {
        display: block;
      }
    }
  }

  &.active {
    .collaped_container--actions {
      display: block;
    }
  }

  &--actions {
    display: none;
    &__next {
      width: 100%;
      color: $c-white;
      background-color: $c-default-template;
      &:not(:disabled):hover {
        color: $c-white !important;
        filter: brightness(1.5);
      }
    }
  }
}
.collaped_block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgb(218, 218, 218);
  }
  &--title {
    font-weight: 800;
    font-size: 20px;
    color: rgb(201, 201, 201);
  }
}
.recap_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  &--child {
    padding: 16px;
    background: $c-white;
    border: 1px solid $c-default-border;
    box-shadow: -7px 7px 17px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  &__product {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &--subtitle {
      color: $c-default-border;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      margin-bottom: 8px;
    }
    &--title {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      .product-title {
        color: $c-default-template;
        font-size: 22px;
        font-weight: 700;
      }
      .product-formula {
        color: $c-default-text-gray-light;
        font-size: 12px;
        font-weight: 400;
      }
    }
    &--needs {
      border-top: 1px solid rgb(219, 219, 219);
      border-bottom: 1px solid rgb(219, 219, 219);
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &__node {
        display: flex;
        align-items: center;
        > div {
          color: $c-default-text-black;
          flex: 1 1 0;
        }
        ul {
          display: flex;
          gap: 2px;
          list-style: none;
          > li {
            background: rgb(219, 219, 219);
            border-radius: 4px;
            flex: 1 1 0;
            height: 5px;
            &.active {
              background: $c-default-template;
            }
          }
        }
      }
    }
    &--recap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 0;
      &__node {
        display: flex;
        align-items: center;
        > span {
          flex: 1 1 0;
          color: $c-default-gray;
          font-size: 12px;
          font-weight: 500;
          &:last-child {
            text-align: right;
            color: $c-default-text-black;
            &.price-tag {
              font-size: 22px;
              font-weight: 900;
            }
          }
        }
      }
    }
    &--actions {
      &__edit {
        width: 100%;
        color: $c-default-template;
        border-radius: 6px;
        font-weight: 800;
        border: 2px solid $c-default-template !important;
      }
    }
  }
  &__informations {
    &--title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        color: $c-default-text-gray-light;
        font-size: 18px;
        font-weight: 800;
      }
      .danger--underline {
        color: $c-default-template;
        text-decoration: underline;
        text-align: right;
        font-weight: 700;
        padding: 0;
      }
    }
    &--container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &--recap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 0;
      &__node {
        display: flex;
        > span {
          flex: 1 1 0;
          color: $c-default-gray;
          font-size: 12px;
          font-weight: 500;
          &:last-child {
            text-align: right;
            color: $c-default-text-black;
            font-weight: 800;
          }
        }
      }
    }
    &--spouse {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &_title {
        padding: 12px 0;
        color: $c-default-text-gray-light;
        font-size: 18px;
        font-weight: 800;
      }
    }
    &--children {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .children-block {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      .children-count {
        color: $c-default-danger;
        font-size: 14px;
        font-weight: 700;
      }
      &_title {
        padding: 12px 0;
        color: $c-default-text-gray-light;
        font-size: 18px;
        font-weight: 800;
      }
    }
  }
}

.danger--underline {
  color: $c-default-template;
  text-decoration: underline;
  text-align: right;
  font-weight: 700;
  padding: 0 !important;
  height: auto !important;
}
.resume_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  &--child {
    padding: 16px;
    background: $c-white;
  }
  &__product {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &--subtitle {
      color: $c-default-border;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      margin-bottom: 8px;
    }
    &--title {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      .product-title {
        color: $c-default-template;
        font-size: 22px;
        font-weight: 700;
      }
      .product-formula {
        color: $c-default-text-gray-light;
        font-size: 12px;
        font-weight: 400;
      }
    }
    &--needs {
      border-top: 1px solid rgb(219, 219, 219);
      border-bottom: 1px solid rgb(219, 219, 219);
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &__node {
        display: flex;
        align-items: center;
        > div {
          color: $c-default-text-black;
          flex: 1 1 0;
        }
        ul {
          display: flex;
          gap: 2px;
          list-style: none;
          > li {
            background: rgb(219, 219, 219);
            border-radius: 4px;
            flex: 1 1 0;
            height: 5px;
            &.active {
              background: $c-default-template;
            }
          }
        }
      }
    }
    &--recap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 0;
      &__node {
        display: flex;
        align-items: center;
        > span {
          flex: 1 1 0;
          color: $c-default-gray;
          font-size: 12px;
          font-weight: 500;
          &:last-child {
            text-align: right;
            color: $c-default-text-black;
            &.price-tag {
              font-size: 22px;
              font-weight: 900;
            }
          }
        }
      }
    }
    &--actions {
      &__edit {
        width: 100%;
        color: $c-default-template;
        border-radius: 6px;
        font-weight: 800;
        border: 2px solid $c-default-template !important;
      }
    }
  }
  &__informations {
    &--title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        color: $c-default-text-gray-light;
        font-size: 18px;
        font-weight: 800;
      }
      .danger--underline {
        color: $c-default-template;
        text-decoration: underline;
        text-align: right;
        font-weight: 700;
        padding: 0;
      }
    }
    &--container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &--recap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 0;
      &__node {
        display: flex;
        > span {
          flex: 1 1 0;
          color: $c-default-gray;
          font-size: 12px;
          font-weight: 500;
          &:last-child {
            text-align: right;
            color: $c-default-text-black;
            font-weight: 800;
          }
        }
      }
    }
    &--spouse {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &_title {
        padding: 12px 0;
        color: $c-default-text-gray-light;
        font-size: 18px;
        font-weight: 800;
      }
    }
    &--children {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .children-count {
        color: $c-default-danger;
        font-size: 14px;
        font-weight: 700;
      }
      &_title {
        padding: 12px 0;
        color: $c-default-text-gray-light;
        font-size: 18px;
        font-weight: 800;
      }
    }
  }
  &--card {
    > div {
      padding: 24px 0;
    }
    & > div:not(:last-child) {
      border-bottom: 1px solid rgb(240, 240, 240);
    }
  }
}

.product-drawer {
  &--header {
    color: $c-default-border;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 24px;
    display: block;
  }
  &--container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__card {
      padding: 16px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid $c-default-border;
      background: $c-white;
      box-shadow: -7px 7px 17px 0px rgba(0, 0, 0, 0.04);
      transition: all 0.3s ease-in-out;
      &:hover {
        box-shadow: -7px 7px 17px 0px rgba(0, 0, 0, 0.1);
      }
      &.active {
        border: 2px solid $c-red;
      }
    }
  }
  &--actions {
    margin-top: 16px;
    width: 100%;
    &__save {
      color: $c-white;
      background-color: $c-default-template;
      width: 100%;
      &:not(:disabled):hover {
        color: $c-white !important;
        filter: brightness(1.5);
      }
    }
  }
}

.edit-subscription_wrapper {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid $c-default-border;
  background: $c-white;
  display: flex;
  width: 100%;
  max-width: 840px;
  box-shadow: -7px 7px 17px 0px rgba(0, 0, 0, 0.04);
  flex-direction: column;
  gap: 24px;
  &--header {
    margin-bottom: 16px;
    &__title {
      color: $c-default-text-black;
      font-weight: 700;
      font-size: 24px;
      display: block;
      margin-bottom: 8px;
    }
    &__substitle {
      color: $c-default-text-gray-light;
      font-size: 12px;
      font-weight: 400;
    }
  }
  &--circle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 16px;
    &__icon {
      margin-bottom: 24px;
    }
    &__title {
      color: $c-default-text-black;
      font-weight: 800;
      font-size: 32px;
      display: block;
      margin-bottom: 8px;
      text-align: center;
    }
    &__substitle {
      color: $c-default-text-gray-light;
      font-size: 14px;
      font-weight: 400;
      max-width: 400px;
      text-align: center;
    }
  }
  &--child {
    &.conditions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 16px;
    }
    &__condition {
      color: $c-default-gray;
      font-family: "Public Sans";
      font-size: 12px;
      font-weight: 400;
      display: flex;
      gap: 8px;
      align-items: center;
      .button__switch.ant-switch-checked,
      .button__switch .ant-switch-handle::before {
        background: $c-default-template !important;
      }
      .button__switch .ant-switch-inner-unchecked {
        color: $c-default-template !important;
      }
    }
    &__title {
      color: $c-default-border;
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .radio-group__wrapper .custom--label {
        min-width: auto !important;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
      .children-count {
        color: $c-default-danger;
        font-size: 14px;
        font-weight: 700;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $c-default-border;
    }
  }
  &--actions {
    &__previous {
      color: $c-default-gray;
      border: 1px solid $c-default-gray !important;
      width: 100%;
    }
    &__next {
      color: $c-white;
      background-color: $c-default-template;
      width: 100%;
    }
  }
}

.edit-subscription__previous {
  text-align: right;
  color: $c-default-text-gray-light;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  max-width: 840px;
  display: flex;
  justify-content: flex-end;
}

#iframe-container {
  width: 100%;
  height: 800px;
  border: none;
}

#iframe-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.tag-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 700px;
}
